import * as React from 'react';
import { fetchQuery, graphql } from 'relay-runtime';
import Tabs, {
  TabContent,
  Body,
  Heading,
  LargeText,
  Footer,
  CurrentTotal,
  TermsBox,
  Text,
  CourseList,
  Payment
} from 'sharedComponents/SideModal/Tabs';
import SearchableDropdown, {
  CourseCategory,
  Course
} from 'sharedComponents/core/Input/SearchableDropdown';
import MultiUserSearch from 'components/UserSearch/MultiUserSearch';
import Button from 'sharedComponents/core/Input/Button';
import environment from 'api/environment';
import { TabsDelegateQueryResponse } from './__generated__/TabsDelegateQuery.graphql';
import { TabsCoursesQueryResponse } from './__generated__/TabsCoursesQuery.graphql';
import PaymentSuccess from 'sharedComponents/SideModal/PaymentSuccess';
import LabelledCard from 'sharedComponents/core/Cards/LabelledCard';
import CheckboxSingle from 'components/core/Input/CheckboxSingle';
import ClassroomInstanceSelector from 'sharedComponents/SideModal/ClassroomInstanceSelector';
import Spacer from 'sharedComponents/core/Spacers/Spacer';
import { GraphError } from 'types/general';

const userSearchFunction = async (text: string) => {
  const query = graphql`
    query TabsDelegateQuery($name: String!) {
      candidates(filter: { name: $name }, page: { limit: 8 }) {
        edges {
          id
          firstName
          lastName
        }
      }
    }
  `;

  const variables = {
    name: text
  };

  const data = (await fetchQuery(
    environment,
    query,
    variables
  )) as TabsDelegateQueryResponse;

  if (!data || !data.candidates || !data.candidates.edges) {
    console.error('Could not get data', data);
    return [];
  }

  const results = data.candidates?.edges.map((candidate) => ({
    id: candidate?.id ?? '',
    key: `${candidate?.firstName} ${candidate?.lastName}`,
    value: 'exampleValue'
  }));

  return results;
};

const courseSearchFunction = async (
  categoryUUID?: string,
  secondaryCategoryUUID?: string,
  text?: string
) => {
  const query = graphql`
    query TabsCoursesQuery(
      $categoryUUID: UUID
      $secondaryCategoryUUID: UUID
      $name: String
    ) {
      courses(
        filter: {
          categoryUUID: $categoryUUID
          secondaryCategoryUUID: $secondaryCategoryUUID
          name: $name
        }
        page: { limit: 60 }
        orderBy: { ascending: false, field: "priority" }
      ) {
        edges {
          ident: id
          name
          price
          category {
            uuid
          }
          secondaryCategory {
            uuid
          }
          specificTerms
        }
      }
    }
  `;

  const variables = {
    categoryUUID: categoryUUID,
    secondaryCategoryUUID: secondaryCategoryUUID,
    name: text
  };

  const data = (await fetchQuery(
    environment,
    query,
    variables
  )) as TabsCoursesQueryResponse;

  if (!data || !data.courses || !data.courses.edges) {
    console.error('Could not get course data', data);
    return [];
  }

  const results: Course[] = [];

  data.courses?.edges.forEach((course) => {
    const courseItem = {
      id: course?.ident || 0,
      name: course?.name || '',
      price: course?.price || 0,
      specificTerms: course?.specificTerms || '',
      category: course?.category ?? undefined,
      secondaryCategory: course?.secondaryCategory ?? undefined
    };

    results.push(courseItem);
  });

  return results;
};

export const tabList: (isContract: boolean) => TabContent[] = (isContract) => {
  return [
    {
      key: 'Courses',
      component: ({ state, setState, setTab, closeModal }) => {
        const onSelected = React.useCallback((instance) => {
          setState((s: any) => ({
            ...s,
            courses: [{ ...s.courses[0], instanceUUID: instance.uuid }]
          }));
        }, []);

        return (
          <>
            <Body>
              <Heading>
                Start a Quick Booking{' '}
                {state.users?.filter(Boolean).length > 0 && (
                  <span style={{ color: '#1081AA' }}>
                    for {state.users?.filter(Boolean).length} Delegates
                  </span>
                )}
              </Heading>
              <LargeText>Select your Course</LargeText>
              <SearchableDropdown
                selected={state.courses}
                categories={state.categories?.edges ?? []}
                searchQuery={courseSearchFunction}
                setSelected={(selected) => {
                  setState((s: object) => ({ ...s, courses: selected }));
                }}
              />
              <Spacer vertical spacing={1} />
              {state.courses?.length > 0 && (
                <ClassroomInstanceSelector
                  courseID={state.courses[0].id || state.courses[0].ident}
                  onSelected={onSelected}
                />
              )}
              <MultiUserSearch
                searchFunction={userSearchFunction}
                users={state.users}
                setUsers={(users) => setState((s: object) => ({ ...s, users }))}
                style={{ marginTop: 25 }}
              />
            </Body>
            <Footer>
              <CurrentTotal
                total={
                  state.courses && state.courses?.length > 0
                    ? state.courses[0].price *
                      state.users?.filter(Boolean).length
                    : 0
                }
              />
              <div style={{ display: 'flex' }}>
                <Button archetype="default" onClick={() => closeModal()}>
                  Cancel
                </Button>
                <Button
                  archetype="submit"
                  onClick={() => setTab('Terms of Business')}
                  style={{ marginLeft: 20 }}
                  disabled={
                    !(
                      state.courses?.length > 0 &&
                      state.users?.length > 0 &&
                      state.users[0]
                    )
                  }
                >
                  Continue to Terms
                </Button>
              </div>
            </Footer>
          </>
        );
      }
    },
    {
      key: 'Terms of Business',
      component: ({ state, setState, closeModal, setTab }) => {
        return (
          <>
            <Body>
              <Heading>Terms of Business</Heading>
              <LargeText>
                To book your {state.users?.filter(Boolean).length} delegate
                {state.users?.length > 1 ? 's' : ''} onto this course you will
                need to agree to our terms of business below.
              </LargeText>
              <TermsBox title="TTC Hub - Terms of Business">
                <Text>
                  ABOUT US  <br />
                  <br />
                  The Training and Compliance Hub Limited (“TTC”, “we” or “us”)
                  is registered in England & Wales with company number 10849230.
                  Our registered address is 168 Wey House 15 Church Street,
                  Weybridge, England, KT13 8NA.
                  <br />
                  <br />
                  Course Booking Terms & Conditions <br />
                  <br />
                  On receipt of a booking, we will send joining instructions and
                  a VAT invoice which shall be payable within 7 working days. We
                  accept most Debit and Credit Cards, BACS payments and Cheques
                  (payable to The Training and Compliance Hub Limited).
                  Certificates are only issued on receipt of full payment and we
                  reserve the right to cancel, postpone or re-schedule any
                  course. If you cancel or move to another course within 14 days
                  of the course start date, a £35 + VAT fee will apply. If a
                  delegate fails to turn up on the day of course, the full
                  course fee shall be payable. Online courses are purchased
                  directly on our website. A username and password will be
                  automatically generated and an account created for the
                  candidate to compete their course/s. Online courses are
                  non-refundable once they have been purchased however, TTC
                  reserves the right to offer a credit or voucher in special
                  circumstances.
                  <br />
                  <br />
                  INTELLECTUAL PROPERTY RIGHTS
                  <br />
                  <br />
                  Unless otherwise explicitly stated, we are the owner of all
                  intellectual property rights in our site and in the material
                  published, including but not limited to any concepts, ideas,
                  methods, procedures, processes, know-how, techniques,
                  programs, publications, models, products, templates,
                  technologies, software designs, art work, graphics and
                  information on or described in our site. All such rights are
                  reserved.  Users are encouraged to distribute content (e.g.
                  via link on a social network) provided that:  <br /> <br />
                  all copyright, trademark and similar notices are retained {' '}
                  <br />
                  the content does not imply that we are providing a testimonial
                  or endorsement of an organisation, its products or services. 
                  <br />
                  <br /> You must not modify, reproduce, publicly display,
                  distribute or use without explicit written permission from the
                  appropriate content or material provider (including
                  third-party links). 
                  <br />
                  <br />
                  NO RELIANCE ON INFORMATION <br />
                  <br />
                  The content on our site is provided for general information
                  only. It is not intended to amount to advice or serve as a
                  substitute for any audit, advisory or other professional
                  advice, consultation or service.
                  <br />
                  <br />
                  OUR LIABILITY <br />
                  <br />
                  To the extent permitted by law, we exclude all conditions,
                  warranties, representations or other terms which may apply to
                  our site or any content on it, whether express or implied,
                  included but not limited to that the site will be
                  uninterrupted, without delay, error-free, omission-free, or
                  free of viruses.  TTC, its related partners, directors, agents
                  or employees will not be liable to any user for any loss or
                  damage arising under or in connection with but not limited
                  to: 
                  <br />
                  use of, or inability to use, our site;  <br /> <br /> or use
                  of, or reliance on, any content displayed on our site. <br />{' '}
                  <br />
                    We have no liability to you for any loss of profit, data,
                  sales or revenue, loss of business, business interruption or
                  business opportunity or any indirect, incidental, special,
                  exemplary, punitive, consequential or other loss or damage. 
                  <br />
                  <br /> VIRUSES  <br />
                  <br />
                  We do not guarantee that our site will be secure or free from
                  bugs or viruses and will not be liable for any loss or damage
                  caused by a virus either through our site or third-party
                  link.  You are responsible for configuring your information
                  technology, computer programmes and platform in order to
                  access our site. You should use your own virus protection
                  software.  You must not misuse our site by knowingly
                  introducing viruses, trojans, worms, logic bombs or other
                  material which is malicious or technologically harmful. You
                  must not attack our site via a denial-of-service attack or a
                  distributed denial-of service attack. By breaching this
                  provision, you would commit a criminal offence under the
                  Computer Misuse Act 1990. 
                  <br />
                  <br />
                  LINKING TO OUR SITE
                  <br />
                  <br />
                  Where our site contains links to other sites and resources
                  provided by third parties, these links are provided for your
                  information and convenience only. We have no control over, and
                  are not responsible for, the contents of those sites or
                  resources.  You must not establish any link without our
                  explicit permission. We reserve the right to withdraw linking
                  permission without notice. 
                </Text>
                {state.courses?.map((course: Course) => (
                  <>
                    <Text>{course.specificTerms}</Text>
                  </>
                ))}
              </TermsBox>
              <CheckboxSingle
                label={'I agree to the TTC Terms of Business'}
                onChange={(checked) =>
                  setState((state: object) => ({
                    ...state,
                    termsAccepted: checked
                  }))
                }
              />
            </Body>
            <Footer>
              <CurrentTotal
                total={
                  state.courses && state.courses?.length > 0
                    ? state.courses[0].price *
                      state.users?.filter(Boolean).length
                    : 0
                }
              />
              <div style={{ display: 'flex' }}>
                <Button archetype="default" onClick={() => closeModal()}>
                  Cancel
                </Button>
                <Button
                  archetype="submit"
                  onClick={() => setTab('Payment')}
                  style={{ marginLeft: 20 }}
                  disabled={!state.termsAccepted}
                >
                  Continue to Payment
                </Button>
              </div>
            </Footer>
          </>
        );
      }
    },
    {
      key: 'Payment',
      component: ({ state, setState, closeModal, setTab }) => (
        <>
          <Body>
            <Heading>Payment</Heading>
            <LargeText>
              You have opted to book{' '}
              {!!state.users ? state.users.filter(Boolean).length : 0} Delegate
              {!!state.users && state.users.length > 1 ? 's' : ''} on the
              following Course
            </LargeText>
            <Payment
              courses={
                !!state.courses && state.courses.length > 0
                  ? state.courses.map((course: any) => ({
                      ...course,
                      id: course.ident || course.id
                    }))
                  : []
              }
              userUUIDs={
                !!state.users
                  ? state.users.map(
                      (user: { uuid: string } | undefined) => user?.uuid
                    )
                  : []
              }
              isContract={isContract}
              onSuccess={() => {
                setState((s: object) => ({ ...s, success: true }));
              }}
              onError={(error: GraphError) => {
                setState((s: object) => ({ ...s, error }));
              }}
            />
            {state.success && <PaymentSuccess total={0} transactionId={``} />}
            {state.error !== undefined && (
              <LabelledCard
                label={'Transaction failed'}
                labelBackground={'#ff7474'}
                button={
                  state.error?.extensions?.type == 'ErrCourseFull' ||
                  state.error?.extensions?.type == 'ErrTooManyParticipants'
                } //if error is about slots left show button
                onClick={() => {
                  setTab('Courses');
                  setState((s: object) => ({ ...s, error: undefined }));
                }}
              >
                {state.error?.message}
              </LabelledCard>
            )}
          </Body>
          <Footer>
            <CurrentTotal
              total={
                state.courses && state.courses.length > 0
                  ? state.courses[0].price * state.users.filter(Boolean).length
                  : 0
              }
            />
            <div style={{ display: 'flex' }}>
              <Button archetype="default" onClick={() => closeModal()}>
                Cancel
              </Button>
              <Button
                archetype="submit"
                onClick={() =>
                  document.getElementById('PaymentForm')?.scrollIntoView()
                }
                style={{ marginLeft: 20 }}
              >
                Scroll to Payment
              </Button>
            </div>
          </Footer>
        </>
      )
    }
  ];
};
