import EasyInput from 'components/core/Input/EasyInput';
import { useToastDispatch } from 'components/Toast/ToastProvider';
import { Theme } from 'helpers/theme';
import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { update } from 'relay-runtime/lib/handlers/connection/ConnectionHandler';
import Button from 'sharedComponents/core/Input/Button';
import { Body, Footer } from 'sharedComponents/SideModal/Tabs';
import { UpdateManager } from './mutations';
import { commitMutation, graphql } from 'react-relay';
import environment from 'api/environment';
import { GraphError } from 'types/general';
import { boolean } from '@storybook/addon-knobs';

const useStyles = createUseStyles((theme: Theme) => ({
  personalContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginBottom: 35
  },
  heading: {
    marginBottom: theme.spacing(2),
    color: theme.colors.primaryBlack,
    fontSize: theme.fontSizes.heading,
    fontWeight: 800
  },
  profilePicContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(3) //"5%"
  },
  editPicPencilIcon: {
    marginBottom: -30,
    marginRight: -65,
    zIndex: 15
  },
  editPhotoBtn: {
    marginTop: theme.spacing(1)
  },
  personalDetailContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  namesContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2)
  },
  marginRight: {
    marginRight: theme.spacing(2)
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  roleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  contactContainer: {
    display: 'flex',
    marginTop: theme.spacing(0),
    marginBottom: 35
  },
  emailInput: {
    marginRight: '7.5%',
    width: '40%'
  },
  telephoneInput: {
    width: '40%'
  },
  footerBtnsContainer: {
    display: 'flex'
  },
  submitBtn: {
    marginLeft: theme.spacing(2)
  },
}));

export type State = {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  telephone?: string;
};

type Props = {
  state: State;
  setState: (newState: object) => void;
  closeModal: () => void;
};


const mutation = graphql`
  mutation Content_ResetRequestMutation(
    $email: String!
    $role: UserType!
  ) {
    resetPasswordRequest(input: { email: $email, role: $role })
  }
`;

const AttemptRecovery = (email: string, setSent: (sent: boolean) => void): Promise<boolean> => {
  return new Promise<boolean> ((r, j) => {
    const variables = {
      email,
      role: 'manager'
    };
  
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (
        response: { resetPasswordRequest: boolean },
        errors: GraphError[]
      ) => {
        if (errors) {
          // Display error
          j(`${errors[0]?.extensions?.message}`);
          return;
        }
        console.log('Response received from server.', response, errors);
        setSent(true);
        r(true)
      },
      onError: (err) => console.error(err)
    });
  })
};


function Content({ state, setState, closeModal }: Props){
  const theme = useTheme();
  const classes = useStyles({ theme });
  const dispatch = useToastDispatch();
  const [sent, setSent] = React.useState(false)

  const onRecover = () => {
    if (state.email === null || state.email === undefined) {
      return
    }
    AttemptRecovery(state.email, setSent).then(()=> {
      dispatch({
        text: 'A password change email has been successfully sent',
        alert: 'success',
        stayFor: 5000
      });
    }).catch((e)=> {
        console.log(e)
    })
  }
  return (
    <>
      <Body>
        <span className={classes.heading}>Personal</span>
        <div className={classes.personalContainer}>
          <div className={classes.namesContainer}>
            <div className={classes.marginRight}>
              <EasyInput
                label="First Name"
                placeholder="e.g. John"
                value={state.firstName}
                onChange={(text) => setState((s: State) => ({ ...s, firstName: text }))}
              />
            </div>
            <div className={classes.marginRight}>
              <EasyInput
                label="Last Name"
                placeholder="e.g. Smith"
                value={state.lastName}
                onChange={(text) => setState((s: State) => ({ ...s, lastName: text }))}
              />
            </div>
          </div>
          <div className={classes.roleContainer}>
            <EasyInput
              label="Role"
              placeholder="e.g. Supervisor"
              value="Supervisor"
              disabled
            />
          </div>
        </div>
        <span className={classes.heading}>Contact</span>
        <div className={classes.contactContainer}>
          <div className={classes.emailInput}>
            <EasyInput
              label="Email"
              type="email"
              placeholder="e.g. example@gmail.com"
              value={state.email}
              onChange={(email) =>
                setState((s: State) => ({ ...s, email }))
              }
            />
          </div>
          <div className={classes.telephoneInput}>
            <EasyInput
              label="Telephone"
              type="tel"
              placeholder="e.g. +44 1234 567890"
              value={state.telephone}
              onChange={(telephone) =>
                setState((s: State) => ({ ...s, telephone }))
              }
            />
          </div>
        </div>
        <div>
        <Button
          disabled={sent}
          style= {{margin: 0}}
            archetype="submit"
            onClick={() => {
              if (sent) {
                return
              } 
              onRecover()
              setSent(true)
            }}
            className={classes.submitBtn}
          >
            Update Password
          </Button>
          </div>
      </Body>
      <Footer>
        <div />
        <div className={classes.footerBtnsContainer}>
          <Button archetype="default" onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button
            archetype="submit"
            onClick={() => {
              const manager = { ...state };
              UpdateManager(
                manager,
                (err) => dispatch({
                  alert: 'error',
                  text: err,
                  stayFor: 6000
                }),
                (success) => {
                  setState((s: State) => ({
                    ...s,
                    ...success.updateManager
                  }));
                  closeModal();
                  dispatch({
                    alert: 'success',
                    text: 'Your profile has successfully been updated!',
                    stayFor: 4000
                  });
                }
              );
            }}
            className={classes.submitBtn}
          >
            Update
          </Button>
        </div>
      </Footer>
    </>
  );
}

export default Content;