/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CandidatesPage_candidates = {
    readonly edges: ReadonlyArray<{
        readonly uuid: string;
        readonly email: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly lastLogin: string;
        readonly createdAt: string | null;
        readonly areaProgress: ReadonlyArray<{
            readonly practiceArea: {
                readonly id: string;
                readonly name: string;
            };
            readonly practiceSkillsDone: ReadonlyArray<{
                readonly id: string;
            }> | null;
            readonly practiceSkillsRemaining: ReadonlyArray<{
                readonly id: string;
            }> | null;
        }> | null;
    } | null> | null;
    readonly pageInfo: {
        readonly total: number;
        readonly offset: number;
        readonly limit: number;
        readonly given: number;
    } | null;
    readonly " $refType": "CandidatesPage_candidates";
};
export type CandidatesPage_candidates$data = CandidatesPage_candidates;
export type CandidatesPage_candidates$key = {
    readonly " $data"?: CandidatesPage_candidates$data;
    readonly " $fragmentRefs": FragmentRefs<"CandidatesPage_candidates">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v1 = [
        (v0 /*: any*/)
    ];
    return {
        "kind": "Fragment",
        "name": "CandidatesPage_candidates",
        "type": "CandidatePage",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "Candidate",
                "plural": true,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "uuid",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "email",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastName",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastLogin",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "areaProgress",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PracticeAreaProgress",
                        "plural": true,
                        "selections": [
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "practiceArea",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "PracticeArea",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "name",
                                        "args": null,
                                        "storageKey": null
                                    }
                                ]
                            },
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "practiceSkillsDone",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "PracticeSkill",
                                "plural": true,
                                "selections": (v1 /*: any*/)
                            },
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "practiceSkillsRemaining",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "PracticeSkill",
                                "plural": true,
                                "selections": (v1 /*: any*/)
                            }
                        ]
                    }
                ]
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "total",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "offset",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "limit",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "given",
                        "args": null,
                        "storageKey": null
                    }
                ]
            }
        ]
    } as any;
})();
(node as any).hash = '5722af8288a637c1b808f4a894672129';
export default node;
