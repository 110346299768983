/* tslint:disable */
/* eslint-disable */
/* @relayHash 115eec6c23086b1b3a72a692041fe15a */

import { ConcreteRequest } from "relay-runtime";
export type MultiUser_fetch2_QueryVariables = {};
export type MultiUser_fetch2_QueryResponse = {
    readonly manager: {
        readonly firstName: string;
        readonly lastName: string;
        readonly client: {
            readonly isContract: boolean;
        };
    } | null;
    readonly categories: {
        readonly edges: ReadonlyArray<{
            readonly name: string;
            readonly uuid: string | null;
            readonly classroomOnly: boolean;
            readonly secondaryCategories: ReadonlyArray<{
                readonly uuid: string | null;
                readonly name: string;
            } | null> | null;
        } | null> | null;
    } | null;
};
export type MultiUser_fetch2_Query = {
    readonly response: MultiUser_fetch2_QueryResponse;
    readonly variables: MultiUser_fetch2_QueryVariables;
};



/*
query MultiUser_fetch2_Query {
  manager {
    firstName
    lastName
    client {
      isContract
      id
    }
    id
  }
  categories {
    edges {
      name
      uuid
      classroomOnly
      secondaryCategories {
        uuid
        name
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
    } as any), v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "lastName",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "isContract",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "uuid",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "categories",
        "storageKey": null,
        "args": null,
        "concreteType": "CategoryPage",
        "plural": false,
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "Category",
                "plural": true,
                "selections": [
                    (v3 /*: any*/),
                    (v4 /*: any*/),
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "classroomOnly",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "secondaryCategories",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SecondaryCategory",
                        "plural": true,
                        "selections": [
                            (v4 /*: any*/),
                            (v3 /*: any*/)
                        ]
                    }
                ]
            }
        ]
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "MultiUser_fetch2_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "manager",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Manager",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "client",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Client",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/)
                            ]
                        }
                    ]
                },
                (v5 /*: any*/)
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "MultiUser_fetch2_Query",
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "manager",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Manager",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "client",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Client",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v6 /*: any*/)
                            ]
                        },
                        (v6 /*: any*/)
                    ]
                },
                (v5 /*: any*/)
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "MultiUser_fetch2_Query",
            "id": null,
            "text": "query MultiUser_fetch2_Query {\n  manager {\n    firstName\n    lastName\n    client {\n      isContract\n      id\n    }\n    id\n  }\n  categories {\n    edges {\n      name\n      uuid\n      classroomOnly\n      secondaryCategories {\n        uuid\n        name\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '81339b2eb35347b31dccc2a1e424e199';
export default node;
