import * as React from 'react';
import { createUseStyles } from 'react-jss';
import ThemeObject, { Theme } from 'helpers/theme';
import classNames from 'classnames';
import Icon from '../../../../sharedComponents/core/Icon';
import { ScaleLoader } from 'react-spinners';
import CoreInput from '../CoreInput';
import Spacer from 'sharedComponents/core/Spacers/Spacer';

const useStyles = createUseStyles((theme: Theme) => ({
  tagContainer: {
    width: '100%'
  },
  tag: {
    height: 40,
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: theme.colors.backgroundGrey,
    border: `1px solid ${theme.colors.borderGrey}`,
    borderRadius: theme.buttonBorderRadius,
    fontSize: theme.fontSizes.default,
    padding: [0, 15],
    margin: [0, 15, 15, 0]
  },
  removeTag: {
    margin: [0, 0, 5, 15],
    height: 20,
    width: 12
  },
  container: {
    width: '100%',
    height: 40,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'strech'
  },
  clickableBox: {
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: [0, 15],
    border: `1px solid ${theme.colors.borderGrey}`,
    borderRadius: theme.buttonBorderRadius,
    cursor: 'pointer'
  },
  dropdown: {
    backgroundColor: 'white',
    position: 'absolute',
    top: 50,
    width: '100%',
    maxHeight: 450, // don't know how this should be calculated
    border: `1px solid ${theme.colors.borderGrey}`,
    borderRadius: theme.primaryBorderRadius,
    display: 'flex',
    flexDirection: 'column',
    zIndex: 2
  },
  searchContainer: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'strech',
    position: 'relative'
  },
  searchIcon: {
    position: 'absolute',
    top: 21,
    right: 21
  },
  searchBox: {
    height: 40,
    border: `1px solid ${theme.colors.borderGrey}`,
    borderRadius: 3,
    padding: [0, 10],
    fontSize: 14,
    flex: 'auto',
    '&:focus': {
      borderColor: theme.colors.primaryBlue
    }
  },
  tempResults: {
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: `1px solid ${theme.colors.borderGrey}`
  },
  tempPlaceholder: {
    color: theme.colors.textGrey
  },
  searchResults: {
    overflowY: 'auto'
  },
  option: {
    height: 40,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 300,
    color: theme.colors.secondaryBlack,
    padding: [0, 15],
    fontSize: theme.fontSizes.default,
    borderBottom: `1px solid ${theme.colors.borderGrey}`,
    '&:first-child, &:last-child': {
      borderBottom: 'none'
    }
  },
  optionHover: {
    '&:hover': {
      background: '#f9fafd'
    }
  },
  header: {
    backgroundColor: theme.colors.primaryBlue,
    cursor: 'default'
  },
  title: {
    textTransform: 'uppercase',
    color: 'white',
    fontWeight: 800
  },
  pill: {
    textTransform: 'uppercase',
    color: 'white',
    fontSize: 9,
    border: '1px solid white',
    borderRadius: 10,
    padding: [2, 10]
  },
  checkbox: {
    marginRight: 15
  },
  // CSS triangle hack :)
  // https://css-tricks.com/snippets/css/css-triangle/
  triangleDown: {
    width: 0,
    height: 0,
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: ` ${5 * 2 * 0.866}px solid ${theme.colors.primaryBlue}`
  },
  triangleUp: {
    width: 0,
    height: 0,
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: `${5 * 2 * 0.866}px solid ${theme.colors.primaryBlue}`
  },
  triangleDownWhite: {
    width: 0,
    height: 0,
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: ` ${5 * 2 * 0.866}px solid ${theme.colors.primaryWhite}`
  },
  triangleUpWhite: {
    width: 0,
    height: 0,
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: `${5 * 2 * 0.866}px solid ${theme.colors.primaryWhite}`
  },
  placeholderText: {
    fontSize: theme.fontSizes.small,
    fontWeight: 300,
    color: theme.colors.primaryBlack
  },
  leftItems: {
    display: 'flex',
    alignItems: 'center'
  }
}));

export type CourseCategory = {
  title: string;
  courses: Course[];
};

export type Course = {
  id: number | string; // not sure yet
  name: string;
  price: number;
  specificTerms: string;
  category?: {
    uuid: string | null;
  };
  secondaryCategory?: {
    uuid: string | null;
  };
};

export type Category = {
  uuid: string;
  name: string;
  secondaryCategories: SecondaryCategory[];
  classroomOnly: boolean;
};

export type SecondaryCategory = {
  uuid: string;
  name: string;
  hasCourses?: boolean;
};

const NO_CATEGORY_ID = 'no-category';

function composeDropdownID(
  categoryUUID?: string,
  secondaryCategoryUUID?: string
) {
  if (secondaryCategoryUUID && categoryUUID) {
    return `${categoryUUID}:${secondaryCategoryUUID}`;
  }
  if (categoryUUID) {
    return categoryUUID;
  }

  return NO_CATEGORY_ID;
}

function explodeDropdownID(dropdownID: string) {
  if (dropdownID === NO_CATEGORY_ID) {
    return {};
  }
  const decomposedID = dropdownID.split(':');
  switch (decomposedID.length) {
    case 1:
      return {
        categoryUUID: decomposedID[0]
      };
      break;
    case 2:
      return {
        categoryUUID: decomposedID[0],
        secondaryCategoryUUID: decomposedID[1]
      };
      break;
    default:
      throw new Error('Unable to decompose dropdown ID');
      break;
  }
}

type DropdownState = {
  courses: Course[];
  dropped: boolean;
  hidden: boolean;
  name: string;
  classroomOnly: boolean;
  loading: boolean;
};

type ReducerState = {
  [key: string]: DropdownState;
};

type ActionType =
  | 'set-categories'
  | 'set-dropdown-courses'
  | 'set-all-courses'
  | 'set-dropdown-loading';
type Action = {
  type: ActionType;
  categories?: Category[];
  dropdownID?: string;
  dropped?: boolean;
  courses?: Course[];
  loading?: boolean;
};

function dropdownReducer(state: ReducerState, action: Action) {
  switch (action.type) {
    case 'set-categories':
      if (!action.categories) return;
      const newDropdowns = {};
      action.categories.forEach((category) => {
        if (
          category.secondaryCategories &&
          category.secondaryCategories.length > 0
        ) {
          category.secondaryCategories.forEach((secondary) => {
            if (secondary.hasCourses === false) {
              return;
            }
            newDropdowns[composeDropdownID(category.uuid, secondary.uuid)] = {
              dropped: false,
              name: `${category.name} - ${secondary.name}`,
              classroomOnly: category.classroomOnly,
              courses: []
            };
          });
        } else {
          newDropdowns[composeDropdownID(category.uuid, undefined)] = {
            dropped: false,
            name: category.name,
            classroomOnly: category.classroomOnly,
            courses: []
          };
        }
      });
      return newDropdowns;
    case 'set-dropdown-courses':
      if (!action.dropdownID)
        throw new Error('dropdownID required to update courses');
      return {
        ...state,
        [action.dropdownID]: {
          ...state[action.dropdownID],
          courses: action.courses,
          dropped: action.dropped
        }
      };
    case 'set-dropdown-loading':
      if (!action.dropdownID)
        throw new Error('dropdownID required to update loading');

      return {
        ...state,
        [action.dropdownID]: {
          ...state[action.dropdownID],
          loading: action.loading
        }
      };
    case 'set-all-courses':
      // Search through results putting them in the right dropdown
      if (!action.courses) {
        throw new Error("courses required for dispatch 'set-all-courses'");
        return;
      }
      const initialState = { ...state };
      const newState = {};

      for (const [key, dropdownItem] of Object.entries(initialState)) {
        newState[key] = {
          ...dropdownItem,
          hidden: true,
          courses: []
        };
      }
      action.courses.map((course) => {
        const dropdownID = composeDropdownID(
          course?.category?.uuid ?? undefined,
          course?.secondaryCategory?.uuid ?? undefined
        );
        if (initialState[dropdownID]) {
          newState[dropdownID] = {
            ...initialState[dropdownID],
            courses: [...(newState[dropdownID]?.courses ?? []), course],
            hidden: false
          };
        }
      });
      return newState;
  }
}

type Props = {
  multiselect?: boolean;
  selected: Course[];
  categories: Category[];
  setSelected: (selected: Course[]) => void;
  searchQuery: (
    categoryUUID?: string,
    secondaryCategoryUUID?: string,
    text?: string
  ) => Promise<Course[]>;
  debounceTime?: number;
};

function SearchableDropdown({
  multiselect,
  selected = [],
  categories,
  setSelected,
  searchQuery,
  debounceTime = 600
}: Props) {
  const classes = useStyles();
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>('');
  const [dropdowns, dispatch]: [
    ReducerState | undefined,
    React.Dispatch<Action>
  ] = React.useReducer(dropdownReducer, {});
  const searchRef = React.useRef<HTMLInputElement>(null);
  const debounceRef = React.useRef<number | undefined>();

  const placeholder = multiselect
    ? 'Please select one or more Courses'
    : selected.length > 0
    ? selected[0].name
    : 'Please select the Course you wish to book';

  React.useEffect(() => {
    dispatch({
      type: 'set-categories',
      categories
    });
  }, [categories]);

  React.useEffect(() => {
    if (isOpen && searchRef && searchRef.current) {
      searchRef.current.focus();
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (search === '') {
      dispatch({
        type: 'set-categories',
        categories
      });
      setLoading(false);
      return;
    }

    setLoading(true);
    clearTimeout(debounceRef.current);
    debounceRef.current = window.setTimeout(async () => {
      const results = await searchQuery(undefined, undefined, search);
      // Search through results putting them in the right dropdown
      dispatch({
        type: 'set-all-courses',
        courses: results
      });
      setLoading(false);
    }, debounceTime);

    return () => {
      clearTimeout(debounceRef.current);
    };
  }, [search, debounceTime, searchQuery]);

  return (
    <>
      {multiselect && selected.length > 0 && (
        <div className={classes.tagContainer}>
          {selected.map(({ name, id }: Course) => (
            <div className={classes.tag} key={id}>
              {name}
              <Icon
                pointer
                name="RemoveSelectedCourse_X"
                className={classes.removeTag}
                size={null} // set in class
                onClick={() => setSelected(selected.filter((f) => f.id !== id))}
              />
            </div>
          ))}
        </div>
      )}
      <div className={classes.container}>
        <div
          className={classes.clickableBox}
          onClick={() => setOpen((o) => !o)}
        >
          <p className={classes.placeholderText}>{placeholder}</p>
          <div className={isOpen ? classes.triangleUp : classes.triangleDown} />
        </div>
        {isOpen && (
          <div className={classes.dropdown}>
            <div className={classes.searchContainer}>
              <Icon
                name="SearchGlass"
                className={classes.searchIcon}
                size={20}
              />
              <CoreInput
                ref={searchRef}
                placeholder="Search"
                type="text"
                onChange={(text) => setSearch(text)}
                value={search}
                className={classes.searchBox}
              />
            </div>
            {isLoading ? (
              <div className={classes.tempResults}>
                <ScaleLoader
                  color={ThemeObject.colors.borderGrey}
                  height={20}
                  loading={isLoading}
                />
              </div>
            ) : categories.length === 0 ? (
              <div className={classes.tempResults}>
                <p className={classes.tempPlaceholder}>No Courses found</p>
              </div>
            ) : (
              <div className={classes.searchResults}>
                {dropdowns &&
                  Object.entries(dropdowns).map(
                    (
                      [
                        dropdownID,
                        {
                          name,
                          courses,
                          dropped,
                          hidden,
                          classroomOnly,
                          loading
                        }
                      ],
                      index
                    ) =>
                      hidden ? null : (
                        <div key={dropdownID}>
                          <div
                            className={classNames(
                              classes.header,
                              classes.option
                            )}
                            onClick={async () => {
                              dispatch({
                                type: 'set-dropdown-loading',
                                dropdownID,
                                loading: true
                              });

                              const idents = explodeDropdownID(dropdownID);
                              const courses = await searchQuery(
                                idents.categoryUUID,
                                idents.secondaryCategoryUUID,
                                search
                              );

                              dispatch({
                                type: 'set-dropdown-courses',
                                courses: courses,
                                dropdownID: dropdownID,
                                dropped: !dropped
                              });
                              dispatch({
                                type: 'set-dropdown-loading',
                                dropdownID,
                                loading: false
                              });
                            }}
                          >
                            <span className={classes.leftItems}>
                              <span
                                className={
                                  dropped
                                    ? classes.triangleUpWhite
                                    : classes.triangleDownWhite
                                }
                                style={{ transition: 'all 0.3s' }}
                              />
                              <Spacer horizontal spacing={2} />
                              <span className={classes.title}>{name}</span>
                              <Spacer horizontal spacing={2} />
                            </span>
                            <span className={classes.pill}>
                              {classroomOnly ? 'CLASSROOM' : 'ONLINE'}
                            </span>
                          </div>
                          {courses.map(
                            ({ name, id, price, specificTerms }: Course) => {
                              return dropped ? (
                                <div
                                  key={id}
                                  className={classNames(
                                    classes.option,
                                    classes.optionHover
                                  )}
                                  onClick={() => {
                                    if (multiselect) {
                                      if (
                                        selected
                                          .map((course) => course.id)
                                          .includes(id)
                                      ) {
                                        setSelected(
                                          selected.filter((f) => f.id !== id)
                                        );
                                      } else {
                                        setSelected([
                                          ...selected,
                                          {
                                            name,
                                            id,
                                            price,
                                            specificTerms
                                          }
                                        ]);
                                      }
                                    } else {
                                      setSelected([
                                        {
                                          name,
                                          id,
                                          price,
                                          specificTerms
                                        }
                                      ]);
                                      setSearch('');
                                      setOpen(false);
                                    }
                                  }}
                                >
                                  <span>
                                    {multiselect &&
                                      (selected
                                        .map((course) => course.id)
                                        .includes(id) ? (
                                        <Icon
                                          name="FormCheckbox_Checked"
                                          className={classes.checkbox}
                                          size={17}
                                        />
                                      ) : (
                                        <Icon
                                          name="FormCheckbox_Unchecked"
                                          className={classes.checkbox}
                                          size={17}
                                        />
                                      ))}
                                    {name}
                                  </span>
                                  <span>£{price.toFixed(2)}</span>
                                </div>
                              ) : null;
                            }
                          )}
                        </div>
                      )
                  )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default SearchableDropdown;
