/* tslint:disable */
/* eslint-disable */
/* @relayHash ea173a48a21ed1927213d1914a029b6e */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type App_Login_QueryVariables = {};
export type App_Login_QueryResponse = {
    readonly manager: {
        readonly " $fragmentRefs": FragmentRefs<"LoginPage_manager">;
    } | null;
    readonly user: {
        readonly " $fragmentRefs": FragmentRefs<"LoginPage_user">;
    } | null;
};
export type App_Login_Query = {
    readonly response: App_Login_QueryResponse;
    readonly variables: App_Login_QueryVariables;
};



/*
query App_Login_Query {
  manager {
    ...LoginPage_manager
    id
  }
  user {
    ...LoginPage_user
  }
}

fragment LoginPage_manager on Manager {
  firstName
  lastName
}

fragment LoginPage_user on User {
  type
}
*/

const node: ConcreteRequest = ({
    "kind": "Request",
    "fragment": {
        "kind": "Fragment",
        "name": "App_Login_Query",
        "type": "Query",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "manager",
                "storageKey": null,
                "args": null,
                "concreteType": "Manager",
                "plural": false,
                "selections": [
                    {
                        "kind": "FragmentSpread",
                        "name": "LoginPage_manager",
                        "args": null
                    }
                ]
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                    {
                        "kind": "FragmentSpread",
                        "name": "LoginPage_user",
                        "args": null
                    }
                ]
            }
        ]
    },
    "operation": {
        "kind": "Operation",
        "name": "App_Login_Query",
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "manager",
                "storageKey": null,
                "args": null,
                "concreteType": "Manager",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastName",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "id",
                        "args": null,
                        "storageKey": null
                    }
                ]
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "type",
                        "args": null,
                        "storageKey": null
                    }
                ]
            }
        ]
    },
    "params": {
        "operationKind": "query",
        "name": "App_Login_Query",
        "id": null,
        "text": "query App_Login_Query {\n  manager {\n    ...LoginPage_manager\n    id\n  }\n  user {\n    ...LoginPage_user\n  }\n}\n\nfragment LoginPage_manager on Manager {\n  firstName\n  lastName\n}\n\nfragment LoginPage_user on User {\n  type\n}\n",
        "metadata": {}
    }
} as any);
(node as any).hash = 'de6c1c7b770940aada95dc519bc09020';
export default node;
