import * as React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import environment from 'api/environment';
import styled from 'styled-components';
import { CandidateCoursesPageQueryResponse } from './__generated__/CandidateCoursesPageQuery.graphql';
import Text from 'sharedComponents/core/Table/Text/Text';
import Table from 'sharedComponents/core/Table';
import CandidateCourseProgress from './CandidateCourseProgress';
import Spacer from 'sharedComponents/core/Spacers/Spacer';
import theme from 'helpers/theme';
import Paginator, { Page } from 'sharedComponents/Pagination/Paginator';
import ActivityTable from 'components/Delegate/ActivityTable';

const Title = styled.div`
  font-weight: 800;
  font-size: 17px;
`;

const CertButton = styled.a`
  background-color: #f37a21;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  padding: 10px;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 15px;
`;

const CertButtonNotAvailable = styled(CertButton)`
  background-color: #c6c7ca;
  color: black;
  cursor: auto;
  min-width: 100px;
`;

const NoneFoundMessage = styled.div`
  color: ${theme.colors.textGrey};
  text-align: center;
`;

const PaginationContainer = styled.div`
  margin-bottom: 60px;
`;

const courseRow = (
  ident: string,
  name: string,
  category: string,
  progress: any,
  status: string,
  certificateURL: string | null
): any => {
  return {
    key: ident,
    cells: [
      { component: () => <Text text={name} /> },
      { component: () => <Text text={category} /> },
      {
        component: () => (
          <CandidateCourseProgress progress={progress} status={status} />
        )
      },
      { component: () => <Text text={uppercaseFirstLetter(status)} /> },
      {
        component: () =>
          certificateURL ? (
            <CertButton target="_blank" href={certificateURL}>
              Certificate
            </CertButton>
          ) : (
            <CertButtonNotAvailable>Not Available</CertButtonNotAvailable>
          )
      }
    ]
  };
};

const uppercaseFirstLetter = (text: string): string => {
  return text[0].toUpperCase() + text.slice(1).toLowerCase();
};

type Props = {
  candidateUUID: string;
};

const OFFSET = 0;
const LIMIT = 10;
const OFFSET_ACTIVITY = 0;
const LIMIT_ACTIVITY = 10;

function CandidateCoursesPage({ candidateUUID }: Props) {
  const [offset, setOffset] = React.useState(OFFSET);
  const [limit, setLimit] = React.useState(LIMIT);
  const [offsetActivity, setOffsetActivity] = React.useState(OFFSET_ACTIVITY);
  const [limitActivity, setLimitActivity] = React.useState(LIMIT_ACTIVITY);

  React.useEffect(() => {
    setOffset(OFFSET);
    setLimit(LIMIT);
    setOffsetActivity(OFFSET_ACTIVITY);
    setLimitActivity(LIMIT_ACTIVITY);
  }, [candidateUUID]);

  React.useEffect(() => {}, [offsetActivity, limitActivity]);

  const onUpdatePage = ({ offset, limit }: Page) => {
    if (offset <= 0) {
      offset = 1;
    }

    setLimit(limit);
    setOffset((offset - 1) * limit);
  };

  const onUpdatePageActivity = ({ offset, limit }: Page) => {
    if (offset <= 0) {
      offset = 1;
    }

    setLimitActivity(limit);
    setOffsetActivity((offset - 1) * limit);
  };

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query CandidateCoursesPageQuery(
          $uuid: UUID
          $offset: Int
          $limit: Int
        ) {
          candidate(uuid: $uuid) {
            id
            firstName
            myCourses {
              status
              certificateURL
              certificateName
              course {
                ident: id
                name
                category {
                  name
                }
              }
              progress {
                total
                completed
                percent
              }
            }
            activity(page: { limit: $limit, offset: $offset }) {
              ...ActivityTable_activity
            }
          }
        }
      `}
      variables={{
        uuid: candidateUUID,
        offset: offsetActivity || 0,
        limit: limitActivity || 10
      }}
      render={({ error, props }) => {
        if (!props) {
          return <div></div>;
        }

        const { candidate } = props as CandidateCoursesPageQueryResponse;
        const courses = candidate?.myCourses ?? [];
        if (courses.length === 0) {
          return (
            <NoneFoundMessage>
              No courses found for this candidate.
            </NoneFoundMessage>
          );
        }

        const page = {
          currentPage: Math.ceil(offset / limit) + 1,
          numPages: Math.ceil(courses.length / limit)
        };

        const courseComponents = courses
          .slice(offset, offset + limit)
          .map((course: any, index: any) =>
            courseRow(
              index,
              course?.course?.name || '',
              course?.course?.category?.name || '',
              course?.progress,
              course?.status,
              course?.certificateURL
            )
          );

        return (
          <>
            <Title>Courses</Title>
            <Spacer spacing={2} vertical />
            <Table
              header={[
                'Course Title',
                'Category',
                'Progress',
                'Status',
                'Certificate'
              ]}
              rows={courseComponents}
            />
            <Spacer spacing={2} vertical />
            <PaginationContainer>
              <Paginator
                currentPage={page.currentPage}
                updatePage={onUpdatePage}
                numPages={page.numPages ?? 1}
                itemsPerPage={limit}
                showRange={page.numPages} //{(page.numPages ?? 1) > 4 ? 4 : page.numPages ?? 1}
              />
            </PaginationContainer>
            <Spacer vertical spacing={2} />
            {candidate?.activity && (
              <>
                <ActivityTable
                  activity={candidate?.activity}
                  userName={candidate?.firstName || ''}
                  onUpdatePage={onUpdatePageActivity}
                />
                <Spacer vertical spacing={3} />
              </>
            )}
          </>
        );
      }}
    />
  );
}

export default CandidateCoursesPage;
