/* tslint:disable */
/* eslint-disable */
/* @relayHash 493eb3ed6fe7b547b85d32401545e0d0 */

import { ConcreteRequest } from "relay-runtime";
export type SupervisorSignOffPageMutationVariables = {
    token: string;
};
export type SupervisorSignOffPageMutationResponse = {
    readonly signoffApproval: {
        readonly token: string;
    } | null;
};
export type SupervisorSignOffPageMutation = {
    readonly response: SupervisorSignOffPageMutationResponse;
    readonly variables: SupervisorSignOffPageMutationVariables;
};



/*
mutation SupervisorSignOffPageMutation(
  $token: String!
) {
  signoffApproval(input: {token: $token}) {
    token
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "token",
            "type": "String!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "signoffApproval",
            "storageKey": null,
            "args": [
                {
                    "kind": "ObjectValue",
                    "name": "input",
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "token",
                            "variableName": "token"
                        }
                    ]
                }
            ],
            "concreteType": "AuthToken",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "token",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "SupervisorSignOffPageMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "SupervisorSignOffPageMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "SupervisorSignOffPageMutation",
            "id": null,
            "text": "mutation SupervisorSignOffPageMutation(\n  $token: String!\n) {\n  signoffApproval(input: {token: $token}) {\n    token\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '170f5759ba1346e9d261196a506b598f';
export default node;
