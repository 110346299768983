import * as React from 'react';
import styled from 'styled-components';
import Text from 'sharedComponents/core/Table/Text';
import { graphql, QueryRenderer } from 'react-relay';
import environment from 'api/environment';
import Spacer from 'sharedComponents/core/Spacers/Spacer';
import moment from 'moment';
import Table from 'sharedComponents/core/Table';
import theme from 'helpers/theme';
import Paginator, { Page } from 'sharedComponents/Pagination/Paginator';
import { PortfolioPageQueryRendererQueryResponse } from './__generated__/PortfolioPageQueryRendererQuery.graphql';
import { IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const NoneFoundMessage = styled.div`
  color: ${theme.colors.textGrey};
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
`;

var nameMap = {
  draft: 'Draft',
  submitted: 'Pending', //'Submitted',
  signedoff: 'Approved' //'Signed-off'
};

type Props = {
  candidateUUID: string;
  searchText: string;
};

const OFFSET = 0;
const LIMIT = 10;

function PortfolioPageQueryRenderer({ candidateUUID, searchText }: Props) {
  const [offset, setOffset] = React.useState(OFFSET);
  const [limit, setLimit] = React.useState(LIMIT);

  React.useEffect(() => {
    setOffset(OFFSET);
    setLimit(LIMIT);
  }, [candidateUUID, searchText]);

  const onUpdatePage = ({ offset, limit }: Page) => {
    if (offset <= 0) {
      offset = 1;
    }

    setLimit(limit);
    setOffset((offset - 1) * limit);
  };

  const entryRow = (
    ident: string,
    placement: string,
    title: string,
    startDate: string,
    endDate: string,
    status: string,
    fileUrl?: string
  ): any => {
    return {
      key: ident,
      cells: [
        { component: () => <Text text={placement} /> },
        { component: () => <Text text={title} /> },
        {
          component: () => (
            <Text
              text={startDate ? moment(startDate).format('DD/MM/YYYY') : '-'}
            />
          )
        },
        {
          component: () => (
            <Text text={endDate ? moment(endDate).format('DD/MM/YYYY') : '-'} />
          )
        },
        {
          component: () => <Text text={status} />
        },
        {
          component: () => fileUrl ? (
            <IconButton
              color="default"
              onClick={() => window.open(fileUrl, '_blank')}
            >
              <FontAwesomeIcon icon={faEye}/> 
            </IconButton>
          ): '-'
        }
      ]
    };
  };

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query PortfolioPageQueryRendererQuery(
          $uuid: UUID
          $searchText: String
        ) {
          candidate(uuid: $uuid) {
            id
            firstName
            lastName
            placements {
              edges {
                id
                name
                entries(filter: { title: $searchText }) {
                  edges {
                    id
                    title
                    startDate
                    endDate
                    status
                    placement {
                      name
                    }
                    fileUrl
                  }
                }
              }
            }
          }
        }
      `}
      variables={{
        uuid: candidateUUID,
        searchText: searchText
      }}
      render={({ error, props }) => {
        if (!props) {
          return <div></div>;
        }
        const { candidate } = props as PortfolioPageQueryRendererQueryResponse;

        let entries: any = [];
        candidate?.placements?.edges?.map((placement: any) => {
          placement?.entries?.edges.map((entry: any) => {
            entries.push(entry);
          });
        });

        const entryComponents = entries
          .slice(offset, offset + limit)
          .map((entry: any) =>
            entryRow(
              entry.id,
              entry.placement?.name || '',
              entry.title || '',
              entry.startDate,
              entry.endDate,
              nameMap[entry.status] || '-',
              entry.fileUrl
            )
          );

        const page = {
          currentPage: Math.ceil(offset / limit) + 1,
          numPages: Math.ceil(entries.length / limit)
        };

        return (
          <Container>
            {entries.length === 0 ? (
              <NoneFoundMessage>No entries found.</NoneFoundMessage>
            ) : (
              <Table
                header={[
                  'PLACEMENT',
                  'TITLE',
                  'START DATE',
                  'END DATE',
                  'STATUS',
                  'DOCUMENT'
                ]}
                rows={entryComponents}
              />
            )}
            <Spacer spacing={2} vertical />
            {entries.length > 0 && (
              <>
                <Paginator
                  currentPage={page.currentPage}
                  updatePage={onUpdatePage}
                  numPages={page.numPages ?? 1}
                  itemsPerPage={limit}
                  showRange={page.numPages} //{(page.numPages ?? 1) > 4 ? 4 : page.numPages ?? 1}
                />
                <Spacer spacing={3} vertical />
                <Spacer spacing={2} vertical />
              </>
            )}
          </Container>
        );
      }}
    />
  );
}

export default PortfolioPageQueryRenderer;
