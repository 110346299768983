import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { Theme } from 'helpers/theme';
import Dropdown, { DropdownOption } from 'sharedComponents/core/Input/Dropdown';
import PageNumbers from 'sharedComponents/Pagination/PageNumbers';

const useStyles = createUseStyles((theme: Theme) => ({
  paginatorRoot: {
    display: 'flex'
  },
  pageDropdown: {
    width: 117,
    backgroundColor: theme.colors.primaryWhite
  }
}));

const defaultOptions: DropdownOption[] = [
  {
    id: 0,
    title: 'Show 5',
    component: <div>Show 5</div>
  },
  {
    id: 1,
    title: 'Show 10',
    component: <div>Show 10</div>
  },
  {
    id: 2,
    title: 'Show 20',
    component: <div>Show 20</div>
  }
];

export type Page = {
  offset: number;
  limit: number;
};

type Props = {
  currentPage: number;
  showRange?: number;
  numPages: number;
  updatePage: (page: Page) => void;
  itemsPerPage: number;
  showDropdown?: boolean;
};

function Paginator({
  currentPage,
  numPages,
  showRange = 4,
  updatePage,
  itemsPerPage,
  showDropdown = true
}: Props) {
  const classes = useStyles();
  const [pageNum, setPageNum] = React.useState(0);

  const defaultItemsPerPageDropdownOption = () => {
    switch (itemsPerPage) {
      case 5:
        return defaultOptions[0];
      case 10:
        return defaultOptions[1];
      case 20:
        return defaultOptions[2];
      default:
        return defaultOptions[1];
    }
  };

  const [
    itemsPerPageDropdownOption,
    setItemsPerPageDropDownOption
  ] = React.useState<DropdownOption>(defaultItemsPerPageDropdownOption);

  const dropdownToLimit = (option: DropdownOption) => {
    setItemsPerPageDropDownOption(option);
    switch (option?.id) {
      case 0:
        return 5;
      case 1:
        return 10;
      case 2:
        return 20;
      default:
        return 10;
    }
  };

  const onChange = (offset: number, limit: number) => {
    setPageNum(offset);
    updatePage({ offset, limit });
  };
  
  return (
    <div className={classes.paginatorRoot}>
      {showDropdown && (
        <div className={classes.pageDropdown}>
          <Dropdown
            placeholder="Show 10"
            options={defaultOptions}
            selected={itemsPerPageDropdownOption}
            setSelected={(option: DropdownOption) =>
              onChange(0, dropdownToLimit(option))    // if the selected option changes set the pageNum as first page
            }
          />
        </div>
      )}
      <PageNumbers
        numberOfPages={numPages}
        range={showRange}
        currentPage={currentPage}
        setCurrentPage={(offset: number) =>
          onChange(offset, dropdownToLimit(itemsPerPageDropdownOption))
        }
      />
    </div>
  );
}

export default Paginator;
