/* tslint:disable */
/* eslint-disable */
/* @relayHash bc229a35f346b05f5a22cd36bb192fa8 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OrderBy = {
    ascending?: boolean | null;
    field: string;
};
export type App_CandidatesPage_QueryVariables = {
    offset?: number | null;
    limit?: number | null;
    orderBy?: OrderBy | null;
};
export type App_CandidatesPage_QueryResponse = {
    readonly candidates: {
        readonly " $fragmentRefs": FragmentRefs<"CandidatesPage_candidates">;
    } | null;
    readonly manager: {
        readonly " $fragmentRefs": FragmentRefs<"CandidatesPage_manager">;
    } | null;
};
export type App_CandidatesPage_Query = {
    readonly response: App_CandidatesPage_QueryResponse;
    readonly variables: App_CandidatesPage_QueryVariables;
};



/*
query App_CandidatesPage_Query(
  $offset: Int
  $limit: Int
  $orderBy: OrderBy
) {
  candidates(page: {offset: $offset, limit: $limit}, orderBy: $orderBy) {
    ...CandidatesPage_candidates
  }
  manager {
    ...CandidatesPage_manager
    id
  }
}

fragment CandidatesPage_candidates on CandidatePage {
  edges {
    uuid
    email
    firstName
    lastName
    lastLogin
    createdAt
    areaProgress {
      practiceArea {
        id
        name
      }
      practiceSkillsDone {
        id
      }
      practiceSkillsRemaining {
        id
      }
    }
    id
  }
  pageInfo {
    total
    offset
    limit
    given
  }
}

fragment CandidatesPage_manager on Manager {
  client {
    name
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "offset",
            "type": "Int",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "limit",
            "type": "Int",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "orderBy",
            "type": "OrderBy",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "orderBy",
            "variableName": "orderBy"
        } as any),
        ({
            "kind": "ObjectValue",
            "name": "page",
            "fields": [
                {
                    "kind": "Variable",
                    "name": "limit",
                    "variableName": "limit"
                },
                {
                    "kind": "Variable",
                    "name": "offset",
                    "variableName": "offset"
                }
            ]
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any), v4 = [
        (v2 /*: any*/)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "App_CandidatesPage_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "candidates",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CandidatePage",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "CandidatesPage_candidates",
                            "args": null
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "manager",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Manager",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "CandidatesPage_manager",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "App_CandidatesPage_Query",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "candidates",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CandidatePage",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Candidate",
                            "plural": true,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "uuid",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "email",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "firstName",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "lastName",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "lastLogin",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "createdAt",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "areaProgress",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "PracticeAreaProgress",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "practiceArea",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "PracticeArea",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                (v3 /*: any*/)
                                            ]
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "practiceSkillsDone",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "PracticeSkill",
                                            "plural": true,
                                            "selections": (v4 /*: any*/)
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "practiceSkillsRemaining",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "PracticeSkill",
                                            "plural": true,
                                            "selections": (v4 /*: any*/)
                                        }
                                    ]
                                },
                                (v2 /*: any*/)
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "pageInfo",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "total",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "offset",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "limit",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "given",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "manager",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Manager",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "client",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Client",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v2 /*: any*/)
                            ]
                        },
                        (v2 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "App_CandidatesPage_Query",
            "id": null,
            "text": "query App_CandidatesPage_Query(\n  $offset: Int\n  $limit: Int\n  $orderBy: OrderBy\n) {\n  candidates(page: {offset: $offset, limit: $limit}, orderBy: $orderBy) {\n    ...CandidatesPage_candidates\n  }\n  manager {\n    ...CandidatesPage_manager\n    id\n  }\n}\n\nfragment CandidatesPage_candidates on CandidatePage {\n  edges {\n    uuid\n    email\n    firstName\n    lastName\n    lastLogin\n    createdAt\n    areaProgress {\n      practiceArea {\n        id\n        name\n      }\n      practiceSkillsDone {\n        id\n      }\n      practiceSkillsRemaining {\n        id\n      }\n    }\n    id\n  }\n  pageInfo {\n    total\n    offset\n    limit\n    given\n  }\n}\n\nfragment CandidatesPage_manager on Manager {\n  client {\n    name\n    id\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '7dc3a876078b0fddd59cacef80f0b4d0';
export default node;
