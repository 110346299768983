import theme from 'helpers/theme';
import React from 'react';
import styled from 'styled-components';
import { commitMutation, graphql } from 'react-relay';
import environment from 'api/environment';
import { GraphError } from 'types/general';
import { useToastDispatch } from 'components/Toast/ToastProvider';
import { getSignoffPDF } from 'views/helpers';
import Button from 'sharedComponents/core/Input/Button';
import Spacer from 'sharedComponents/core/Spacers/Spacer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 12px;
  border: 1px solid ${theme.colors.borderGrey};
  padding: 20px;
  margin: 5px 20px 20px 0px;
  box-shadow: 0px 3px 18px #0000000d;
  width: 300px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Name = styled.div`
  font-weight: 800;
  font-size: 17px;
  margin-bottom: 3px;
`;

const PlacementName = styled.div`
  font-size: 13px;
  color: ${theme.colors.textGrey};
  font-weight: bold;
  margin-bottom: 1px;
`;

const Entries = styled.div`
  font-size: 13px;
  color: ${theme.colors.textGrey};
  font-weight: bold;
`;

const EntriesCandidate = styled.div`
  font-weight: 800;
  font-size: 15px;
  margin-bottom: 3px;
`;

const CreatedAt = styled.div`
  font-size: 13px;
  color: ${theme.colors.textGrey};
  font-weight: 300;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Approval = styled.div`
  font-size: 13px;
  font-weight: 700;
  color: #84ea47;
  padding: 9px;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

// const Button = styled.button`
//   background-color: #c6c7ca;
//   color: ${theme.colors.primaryWhite};
//   text-decoration: none;
//   border-radius: 6px;
//   padding: 9px;
//   font-size: 12px;
//   font-weight: 400;
//   justify-content: center;
//   align-items: center;
//   display: flex;
//   cursor: pointer;
//   margin-top: 10px;
// `;

// const ApproveButton = styled(Button)`
//   background-color: #f37a21;
// `;
const mutation = graphql`
  mutation SignoffRequestCardMutation($signoffRequestID: ID!) {
    signoffTokenlessApproval(input: { signoffRequestID: $signoffRequestID })
  }
`;

type Props = {
  firstName: String;
  lastName: String;
  placementName: String;
  entriesLength: string;
  approval: boolean;
  createdAt: string;
  title: string;
  PDFURL: string;
  fileUrl?: string;
  supervisorSignOffPage: boolean; // true: show this card for "Supervisor Signoff" page, false: for "Candidate Signoff" page
  signoffRequestID: string;
  onApprovalSignoff: ((id: string, isApprove: boolean) => void) | null;
};

export default function SignoffRequestCard({
  firstName,
  lastName,
  placementName,
  entriesLength,
  approval,
  title,
  createdAt,
  PDFURL,
  fileUrl,
  supervisorSignOffPage: supervisorView,
  signoffRequestID,
  onApprovalSignoff
}: Props) {
  const dispatch = useToastDispatch();

  const [approved, setApproved] = React.useState(approval);
  const [loading, setLoading] = React.useState(false);

  const attemptApproval = (signoffRequestID: String, approval: boolean) => (
    errorCallback: (err: string) => void
  ) => {
    const variables = {
      signoffRequestID: signoffRequestID
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (resp, errors: GraphError[]) => {
        if (errors) {
          // Display error
          dispatch({
            text: errors[0].message,
            alert: 'error',
            stayFor: 6000
          });
          return;
        } else {
          dispatch({
            text: approval ? 'Entries approved successfully' : 'Entries unapproved successfully',
            alert: 'success',
            stayFor: 4000
          });
        }
      },
      onError: (err: any) => {
        dispatch({
          text: err,
          alert: 'error',
          stayFor: 6000
        });
      }
    });
  };

  const fetchPDF = (resolve: (res: string | null) => void) => {
    setLoading(true);
    getSignoffPDF(signoffRequestID).then((res) => {
      setLoading(false);
      resolve(res); 
    });
  };

 

  return (
    <Container>
      {supervisorView ? (
        <div>
          <InfoContainer>
            <Name>
              {title}
            </Name>
            <PlacementName> {firstName} {lastName}</PlacementName>
            <PlacementName>{placementName}</PlacementName>
            <PlacementName>{title}</PlacementName>
            <Entries>{entriesLength} Entries</Entries>
            <CreatedAt>Sent {createdAt}</CreatedAt>
          </InfoContainer>
          {approved === false ? (
            <Button
              archetype="submit"
              onClick={() => {
                if (onApprovalSignoff) {
                  onApprovalSignoff && onApprovalSignoff(signoffRequestID, !approved)
                } else {
                  attemptApproval(signoffRequestID, !approved)((err) => {
                    console.log(err);
                  }); 
                  setApproved(true);
                }
                 
              }}
            >
              Approve Entries
            </Button>
          ) : (
            <Button
              archetype="default"
              onClick={() => {
                attemptApproval(signoffRequestID, !approved)((err) => {
                  console.log(err);
                });
                setApproved(false);
              }}
            >
              Unapprove Entries
            </Button>
          )}
          <Spacer spacing={0} vertical />
          <Button
            archetype="grey"
            disabled={loading}
            onClick={
              () => fetchPDF((url) => url && window.open(url, '_blank'))
            }
          >
            Download Entries
          </Button>
          {/*<Button target="_blank" href={fileUrl}>
            Download Document
          </Button>*/}
        </div>
      ) : (
        <div>
          <InfoContainer>
            <EntriesCandidate>{entriesLength} Entries</EntriesCandidate>
            <PlacementName>{placementName}</PlacementName>
            <CreatedAt>Sent {createdAt}</CreatedAt>
          </InfoContainer>
          {approved === false ? (
            <Button  
              archetype="submit"
              onClick={() => {
                attemptApproval(signoffRequestID, !approved)((err) => {
                  console.log(err);
                });
                setApproved(true);
              }}
            >
              Approve Entries
            </Button>
          ) : (
            <Button
              archetype="default"
              onClick={() => {
                attemptApproval(signoffRequestID, !approved)((err) => {
                  console.log(err);
                });
                setApproved(false);
              }}
            >
              Unapprove Entries
            </Button>
          )}
          <Spacer spacing={0} vertical />
          <Button
            archetype="grey"
            disabled={loading}
            onClick={
              () => fetchPDF((url) => url && window.open(url, '_blank'))
            }
          >
            Download Entries
          </Button>
      {/*<Button target="_blank" href={fileUrl}>
            Download Document
          </Button>*/}
        </div>
      )}
    </Container>
  );
}
