/* tslint:disable */
/* eslint-disable */
/* @relayHash 8c7e43edc4011398d95a718fb6baa842 */

import { ConcreteRequest } from "relay-runtime";
export type SupervisorSignOffPageApproveMutationVariables = {
    signoffRequestID: string;
};
export type SupervisorSignOffPageApproveMutationResponse = {
    readonly signoffTokenlessApproval: boolean;
};
export type SupervisorSignOffPageApproveMutation = {
    readonly response: SupervisorSignOffPageApproveMutationResponse;
    readonly variables: SupervisorSignOffPageApproveMutationVariables;
};



/*
mutation SupervisorSignOffPageApproveMutation(
  $signoffRequestID: ID!
) {
  signoffTokenlessApproval(input: {signoffRequestID: $signoffRequestID})
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "signoffRequestID",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "signoffTokenlessApproval",
            "args": [
                {
                    "kind": "ObjectValue",
                    "name": "input",
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "signoffRequestID",
                            "variableName": "signoffRequestID"
                        }
                    ]
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "SupervisorSignOffPageApproveMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "SupervisorSignOffPageApproveMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "SupervisorSignOffPageApproveMutation",
            "id": null,
            "text": "mutation SupervisorSignOffPageApproveMutation(\n  $signoffRequestID: ID!\n) {\n  signoffTokenlessApproval(input: {signoffRequestID: $signoffRequestID})\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '7b2b8cb3a37efb7498d858498816d31f';
export default node;
