/* tslint:disable */
/* eslint-disable */
/* @relayHash cc1205973db61452c4c5ec75e6447bc9 */

import { ConcreteRequest } from "relay-runtime";
export type CandidateSignoffPageQueryVariables = {
    candidateID?: string | null;
    limit: number;
    offset: number;
};
export type CandidateSignoffPageQueryResponse = {
    readonly signoffRequests: {
        readonly edges: ReadonlyArray<{
            readonly id: string;
            readonly createdAt: string | null;
            readonly title: string | null;
            readonly PDFURL: string | null;
            readonly approval: boolean | null;
            readonly candidate: {
                readonly firstName: string;
                readonly lastName: string;
                readonly jobTitle: string;
            } | null;
            readonly entries: {
                readonly pageInfo: {
                    readonly total: number;
                } | null;
            } | null;
            readonly placement: {
                readonly name: string;
            } | null;
        } | null> | null;
        readonly pageInfo: {
            readonly total: number;
            readonly limit: number;
            readonly offset: number;
        } | null;
    } | null;
};
export type CandidateSignoffPageQuery = {
    readonly response: CandidateSignoffPageQueryResponse;
    readonly variables: CandidateSignoffPageQueryVariables;
};



/*
query CandidateSignoffPageQuery(
  $candidateID: ID
  $limit: Int!
  $offset: Int!
) {
  signoffRequests(filter: {candidateID: $candidateID}, page: {limit: $limit, offset: $offset}) {
    edges {
      id
      createdAt
      title
      PDFURL
      approval
      candidate {
        firstName
        lastName
        jobTitle
        id
      }
      entries {
        pageInfo {
          total
        }
      }
      placement {
        name
        id
      }
    }
    pageInfo {
      total
      limit
      offset
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "candidateID",
            "type": "ID",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "limit",
            "type": "Int!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "offset",
            "type": "Int!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "ObjectValue",
            "name": "filter",
            "fields": [
                {
                    "kind": "Variable",
                    "name": "candidateID",
                    "variableName": "candidateID"
                }
            ]
        } as any),
        ({
            "kind": "ObjectValue",
            "name": "page",
            "fields": [
                {
                    "kind": "Variable",
                    "name": "limit",
                    "variableName": "limit"
                },
                {
                    "kind": "Variable",
                    "name": "offset",
                    "variableName": "offset"
                }
            ]
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "createdAt",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "title",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "PDFURL",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "approval",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
    } as any), v8 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "lastName",
        "args": null,
        "storageKey": null
    } as any), v9 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "jobTitle",
        "args": null,
        "storageKey": null
    } as any), v10 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "total",
        "args": null,
        "storageKey": null
    } as any), v11 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "entries",
        "storageKey": null,
        "args": null,
        "concreteType": "EntryPage",
        "plural": false,
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                    (v10 /*: any*/)
                ]
            }
        ]
    } as any), v12 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any), v13 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "pageInfo",
        "storageKey": null,
        "args": null,
        "concreteType": "PageInfo",
        "plural": false,
        "selections": [
            (v10 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "limit",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "offset",
                "args": null,
                "storageKey": null
            }
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "CandidateSignoffPageQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "signoffRequests",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "SignoffRequestPage",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "SignoffRequest",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "candidate",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Candidate",
                                    "plural": false,
                                    "selections": [
                                        (v7 /*: any*/),
                                        (v8 /*: any*/),
                                        (v9 /*: any*/)
                                    ]
                                },
                                (v11 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "placement",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Placement",
                                    "plural": false,
                                    "selections": [
                                        (v12 /*: any*/)
                                    ]
                                }
                            ]
                        },
                        (v13 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "CandidateSignoffPageQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "signoffRequests",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "SignoffRequestPage",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "SignoffRequest",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "candidate",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Candidate",
                                    "plural": false,
                                    "selections": [
                                        (v7 /*: any*/),
                                        (v8 /*: any*/),
                                        (v9 /*: any*/),
                                        (v2 /*: any*/)
                                    ]
                                },
                                (v11 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "placement",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Placement",
                                    "plural": false,
                                    "selections": [
                                        (v12 /*: any*/),
                                        (v2 /*: any*/)
                                    ]
                                }
                            ]
                        },
                        (v13 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "CandidateSignoffPageQuery",
            "id": null,
            "text": "query CandidateSignoffPageQuery(\n  $candidateID: ID\n  $limit: Int!\n  $offset: Int!\n) {\n  signoffRequests(filter: {candidateID: $candidateID}, page: {limit: $limit, offset: $offset}) {\n    edges {\n      id\n      createdAt\n      title\n      PDFURL\n      approval\n      candidate {\n        firstName\n        lastName\n        jobTitle\n        id\n      }\n      entries {\n        pageInfo {\n          total\n        }\n      }\n      placement {\n        name\n        id\n      }\n    }\n    pageInfo {\n      total\n      limit\n      offset\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'bfbbf48312284e9a5a043d6bc8280f8d';
export default node;
