/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CandidatePage_candidate = {
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
    readonly placements: {
        readonly edges: ReadonlyArray<{
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"PlacementCard_placement">;
        } | null> | null;
    } | null;
    readonly " $refType": "CandidatePage_candidate";
};
export type CandidatePage_candidate$data = CandidatePage_candidate;
export type CandidatePage_candidate$key = {
    readonly " $data"?: CandidatePage_candidate$data;
    readonly " $fragmentRefs": FragmentRefs<"CandidatePage_candidate">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Fragment",
        "name": "CandidatePage_candidate",
        "type": "Candidate",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            (v0 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "firstName",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastName",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "placements",
                "storageKey": null,
                "args": null,
                "concreteType": "PlacementPage",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Placement",
                        "plural": true,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "kind": "FragmentSpread",
                                "name": "PlacementCard_placement",
                                "args": null
                            }
                        ]
                    }
                ]
            }
        ]
    } as any;
})();
(node as any).hash = 'b9a6ce9464e4b518c71c414a972a424e';
export default node;
