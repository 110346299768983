import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import SideModal from 'sharedComponents/SideModal/SideModal';
import Tabs from 'sharedComponents/SideModal/Tabs';
import Content from './Content';
import { ManagerModal_manager } from './__generated__/ManagerModal_manager.graphql';

export type ManagerInfo = {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  clientID?: string;
};

type Props = {
  isOpen: boolean;
  manager: ManagerModal_manager;
  onClose: () => void;
};

function ManagerSlideIn({ isOpen, manager, onClose }: Props){

  return (
    <SideModal
      title={`${manager.firstName} ${manager.lastName}`}
      closeModal={onClose}
      isOpen={isOpen}
    >
      <Tabs
        content={[
          {
            key: 'Your details',
            component: Content
          }
        ]}
        closeModal={onClose}
        initialState={{...manager}}
      />
    </SideModal>
  );
}

export default createFragmentContainer(ManagerSlideIn, {
  manager: graphql`
    fragment ManagerModal_manager on Manager {
      id
      firstName
      lastName
      email
      telephone
    }
  `
});