import * as React from 'react';
import styled from 'styled-components';
import Spacer from 'sharedComponents/core/Spacers/Spacer';
import SearchBar from 'components/Course/SearchBar';
import PortfolioPageQueryRenderer from './PortfolioPageQueryRenderer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubHeading = styled.div`
  font-weight: 800;
  font-size: 18px;
`;

const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
`;

const SearchBarRow = styled.div`
  width: 30%;
`;

type Props = {
  candidateUUID: string;
};

function PortfolioPage({ candidateUUID }: Props) {
  const [searchText, setSearchText] = React.useState('');

  React.useEffect(() => {}, [candidateUUID]);

  return (
    <Container>
      <SubHeading>Portfolio</SubHeading>
      <Spacer spacing={1} vertical />
      <SearchBarContainer>
        <SearchBarRow>
          <SearchBar
            onChange={(inp: any) => {
              setSearchText(inp);
            }}
            value={searchText || ''}
          />
        </SearchBarRow>
      </SearchBarContainer>
      <Spacer spacing={2} vertical />
      <PortfolioPageQueryRenderer
        candidateUUID={candidateUUID}
        searchText={searchText}
      />
    </Container>
  );
}

export default PortfolioPage;
