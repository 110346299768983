import * as React from 'react';
import styled from 'styled-components';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import PageHeader from 'components/PageHeader';
import PlacementCard from 'components/PlacementCard';
import Spacer from 'sharedComponents/core/Spacers/Spacer';
import { createFragmentContainer, graphql } from 'react-relay';
import { Router, useRouter } from 'found';
import CandidateSignoffPage from './SignOff/CandidateSignoffPage';
import { Tabs, Tab, Divider, colors } from '@material-ui/core';
import CandidateCoursesPage from './CandidateCoursesPage';
import PortfolioPage from './PortfolioPage';

const useStyles = createUseStyles((theme: Theme) => ({
  placementRow: {
    marginTop: '30px',
    display: 'flex',
    flexWrap: 'wrap'
  },
  tabs: {
    marginTop: theme.spacing(2)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const Title = styled.div`
  font-weight: 800;
  font-size: 17px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /*max-width: 1275px;*/
`;

const StyledTab = styled(Tab)`
  && {
    font-size: 15px;
    text-transform: none;
    font-weight: 300;
  }
`;

type Props = {
  candidate: any;
  router: Router;
};

const CandidatePage = ({ candidate }: Props) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [currentTab, setCurrentTab] = React.useState('monthlySignOff');

  React.useEffect(() => {
    setCurrentTab('monthlySignOff');
  }, [candidate]);

  const { router, match } = useRouter();
  let { id } = match.params;

  let tabs = [
    { value: 'monthlySignOff', label: 'Monthly Sign-off' }
  ];

  if (!tabs.find((tab) => tab.value === currentTab)) {
    return <div></div>;
  }

  return (
    <Container>
      <PageHeader
        showCreateButtons
        title={candidate.firstName + ' ' + candidate.lastName}
        subTitle={candidate.email}
      />
      {/*<Title>Placements</Title>
      <div className={classes.placementRow}>
        {candidate.placements.edges.map((placement: any) => (
          <PlacementCard key={placement.id} placement={placement} />
        ))}
        </div>*/}

      <Tabs
        className={classes.tabs}
        onChange={(event, value) => setCurrentTab(value)}
        scrollButtons="auto"
        indicatorColor={'primary'}
        value={currentTab}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <StyledTab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {currentTab === 'monthlySignOff' && (
          <CandidateSignoffPage candidateID={candidate.id} />
        )}
        {currentTab === 'courses' && (
          <CandidateCoursesPage candidateUUID={id} />
        )}
        {currentTab === 'portfolio' && <PortfolioPage candidateUUID={id} />}
      </div>
    </Container>
  );
};

const CandidatePageFrag = createFragmentContainer(CandidatePage, {
  candidate: graphql`
    fragment CandidatePage_candidate on Candidate {
      id
      firstName
      lastName
      email
      placements {
        edges {
          id
          ...PlacementCard_placement
        }
      }
    }
  `
});

export default CandidatePageFrag;
