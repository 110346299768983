import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import classNames from 'classnames';
import { Theme } from 'helpers/theme';
import Icon from '../../../sharedComponents/core/Icon/Icon';
import CircleBorder, { User } from 'sharedComponents/core/CircleBorder';
import AccutraineeLogo from 'assets/logo/accutrainee-logo.svg';

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: [1, 'solid', theme.colors.borderGrey],
    top: 0,
    width: '100%',
    zIndex: 10,
    gridArea: 'header'
  },
  menu: {
    backgroundColor: theme.colors.primaryWhite,
    padding: '17px 38px'
  },
  logo: {
    cursor: 'pointer',
    height: 55,
    width: 140,
    borderRadius: theme.buttonBorderRadius,
    border: [1, 'solid', theme.colors.borderGrey],
    boxShadow: '2px 2px 3px rgba(0, 0, 0, 0.04)',
    objectFit: 'contain'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  body: {
    backgroundColor: theme.colors.backgroundGrey,
    flexGrow: 1
  },
  dropdownWrapper: {
    position: 'absolute',
    top: '80px'
  },
  dropdown: {
    position: 'relative',
    border: ['0.5px', 'solid', theme.colors.borderGrey],
    borderRadius: '8px',
    backgroundColor: theme.colors.primaryWhite,
    boxShadow: '0px 3px 10px #0000001f'
  },
  option: {
    cursor: 'pointer',
    fontSize: theme.fontSizes.large,
    fontWeight: '400',
    margin: '15px'
  },
  siteLabel: {
    background: '#f37a21',
    padding: '5px 22px',
    fontSize: 12,
    borderRadius: 15,
    color: 'white',
    fontWeight: 300
  }
}));

export type Option = {
  id: string;
  title: string;
  onClick: () => void;
};

type Props = {
  logo?: string;
  user: User;
  options?: Option[];
  onLogoClick?: Function;
  className?: string;
};

function HeaderMenu({ logo, user, options, onLogoClick, className }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [showOptions, setShowOptions] = React.useState(false);

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classNames(classes.row, classes.menu)}>
        <div className={classes.siteLabel}>Client Admin</div>
        {logo && (
          <img
            className={classNames(classes.logo)}
            onClick={() => onLogoClick && onLogoClick()}
            src={logo}
            alt="Logo"
          />
        )}

        <div className={classNames(classes.row)}>
          <CircleBorder user={user} />
          <Icon
            name="Card_SecondaryActon_Dots"
            size={20}
            style={{ cursor: 'pointer', margin: '0 20px' }}
            onClick={() => options && setShowOptions((previous) => !previous)}
          />
          {showOptions && (
            <div className={classes.dropdownWrapper}>
              <div className={classes.dropdown}>
                {options &&
                  options.map((option: Option) => (
                    <div
                      key={option.id}
                      className={classes.option}
                      onClick={() => {
                        setShowOptions((previous) => !previous);
                        option.onClick();
                      }}
                    >
                      {option.title}
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default HeaderMenu;
