import React from 'react';
import styled from 'styled-components';
import Spacer from 'sharedComponents/core/Spacers/Spacer';
import ProgressBar from 'sharedComponents/core/ProgressBar';

const ProgressBarCont = styled.div`
  display: flex;
  align-items: center;
`;

const ProgressText = styled.div`
  font-size: 12px;
`;

type Props = {
  progress: any;
  status: string;
};

function CandidateCourseProgress({ progress, status}: Props) {
  //console.log("Progress: ", progress, ", Status: ", status);
  
  // If the course's status is the 'complete' we are getting the 'progress' is null. So, we checked it.
  let percent = 0;
  if(!progress && status == 'complete') {   
    percent = 100;
  } else {
    if(status === 'failed') {
      percent = 0;
    } else {
      percent = progress?.percent;
    }
  }

  return (
    <div>
      <Spacer vertical spacing={1} />
      <ProgressBarCont>
        <ProgressText>{percent}%</ProgressText>
        <Spacer horizontal spacing={1} />
        <ProgressBar
          percent={percent}
          sliderFirstColor="rgb(243, 122, 33)"
          sliderSecondColor="rgb(255, 163, 96)"
        />
      </ProgressBarCont>
    </div>
  );
}

export default CandidateCourseProgress;
