/* tslint:disable */
/* eslint-disable */
/* @relayHash 75c29a6f85e890641a321eda573ae9b1 */

import { ConcreteRequest } from "relay-runtime";
export type SignoffApprovalMutationVariables = {
    token: string;
};
export type SignoffApprovalMutationResponse = {
    readonly signoffApproval: {
        readonly token: string;
    } | null;
};
export type SignoffApprovalMutation = {
    readonly response: SignoffApprovalMutationResponse;
    readonly variables: SignoffApprovalMutationVariables;
};



/*
mutation SignoffApprovalMutation(
  $token: String!
) {
  signoffApproval(input: {token: $token}) {
    token
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "token",
            "type": "String!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "signoffApproval",
            "storageKey": null,
            "args": [
                {
                    "kind": "ObjectValue",
                    "name": "input",
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "token",
                            "variableName": "token"
                        }
                    ]
                }
            ],
            "concreteType": "AuthToken",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "token",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "SignoffApprovalMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "SignoffApprovalMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "SignoffApprovalMutation",
            "id": null,
            "text": "mutation SignoffApprovalMutation(\n  $token: String!\n) {\n  signoffApproval(input: {token: $token}) {\n    token\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '15c0d8bbdae4421dc3a9a5baf45dbb56';
export default node;
