import { graphql, commitMutation } from 'react-relay';
import environment from 'api/environment';
import { GraphError } from 'types/general';
import { mutations_UpdateManagerMutationResponse } from './__generated__/mutations_UpdateManagerMutation.graphql';

const updateManager = graphql`
  mutation mutations_UpdateManagerMutation(
    $id: ID!
    $firstName: String
    $lastName: String
    $email: String
    $telephone: String
    $password: String
  ) {
    updateManager(input: {
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      telephone: $telephone
      password: $password
    }){
      id
      firstName
      lastName
      email
      telephone
    }
  }
`;

export const UpdateManager = (
  manager: {
    id: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    telephone?: string;
  },
  errorCallback: (error: string) => void,
  successCallback: (response: mutations_UpdateManagerMutationResponse) => void
) => {
  const variables = {
    id: manager.id,
    firstName: manager.firstName,
    lastName: manager.lastName,
    email: manager.email,
    telephone: manager.telephone,
  };
  
  commitMutation(environment, {
    mutation: updateManager,
    variables,
    onCompleted: (
      response: mutations_UpdateManagerMutationResponse,
      errors: GraphError[]
    ) => {
      if (errors && errors.length > 0) {
        errorCallback(`${errors[0]?.extensions?.message}`);
        return;
      }
      successCallback(response);
    }
  });
}