import React from 'react';

import LoginDialogue from 'components/LoginDialogue';
import { Theme } from 'helpers/theme';
import environment from 'api/environment';
import { createUseStyles, useTheme } from 'react-jss';
import { commitMutation, graphql, createFragmentContainer } from 'react-relay';
import { GraphError } from 'types/general';
import { useRouter, Router } from 'found';
import { LoginPage_manager } from './__generated__/LoginPage_manager.graphql';
import { LoginPage_user } from './__generated__/LoginPage_user.graphql';

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const mutation = graphql`
  mutation LoginPage_LoginMutation($email: String!, $password: String!) {
    managerLogin(input: { email: $email, password: $password }) {
      token
    }
  }
`;

type Props = {
  manager?: LoginPage_manager;
  user?: LoginPage_user;
};

const AttemptLogin = (router: Router) => (
  email: string,
  password: string,
  errorCallback: (err: string) => void
) => {
  const variables = {
    email,
    password
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: async (
      response: { managerLogin: { token: string } },
      errors: GraphError[]
    ) => {
      if (errors) {
        // Display error
        errorCallback(`${errors[0]?.extensions?.message}`);
        return;
      }
      router.push('/app');
    },
    onError: (err) => errorCallback(`${err}`)
  });
};

const LoginPage = ({ manager, user }: Props) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { router, match } = useRouter();
  const { pathname } = match.location;
  if (
    (pathname === '/' || pathname === '/login') &&
    user &&
    user?.type === 'manager'
  ) {
    match.router.push('/app');
  }

  return (
    <>
      <div className={classes.root}>
        <LoginDialogue onSubmit={AttemptLogin(router)} />
      </div>
    </>
  );
};

export default createFragmentContainer(LoginPage, {
  manager: graphql`
    fragment LoginPage_manager on Manager {
      firstName
      lastName
    }
  `,
  user: graphql`
  fragment LoginPage_user on User {
    type
  }
`
});
