/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SupervisorSignOffPage_signoffRequests = {
    readonly edges: ReadonlyArray<{
        readonly id: string;
        readonly createdAt: string | null;
        readonly title: string | null;
        readonly PDFURL: string | null;
        readonly approval: boolean | null;
        readonly candidate: {
            readonly firstName: string;
            readonly lastName: string;
        } | null;
        readonly entries: {
            readonly edges: ReadonlyArray<{
                readonly fileUrl: string | null;
            } | null> | null;
            readonly pageInfo: {
                readonly total: number;
            } | null;
        } | null;
        readonly placement: {
            readonly name: string;
        } | null;
    } | null> | null;
    readonly pageInfo: {
        readonly total: number;
        readonly offset: number;
        readonly limit: number;
        readonly given: number;
    } | null;
    readonly " $refType": "SupervisorSignOffPage_signoffRequests";
};
export type SupervisorSignOffPage_signoffRequests$data = SupervisorSignOffPage_signoffRequests;
export type SupervisorSignOffPage_signoffRequests$key = {
    readonly " $data"?: SupervisorSignOffPage_signoffRequests$data;
    readonly " $fragmentRefs": FragmentRefs<"SupervisorSignOffPage_signoffRequests">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "total",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Fragment",
        "name": "SupervisorSignOffPage_signoffRequests",
        "type": "SignoffRequestPage",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "SignoffRequest",
                "plural": true,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "id",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "PDFURL",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "approval",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "candidate",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Candidate",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "firstName",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "lastName",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "entries",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "EntryPage",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "QWEEntry",
                                "plural": true,
                                "selections": [
                                    {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "fileUrl",
                                        "args": null,
                                        "storageKey": null
                                    }
                                ]
                            },
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "pageInfo",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "PageInfo",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/)
                                ]
                            }
                        ]
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "placement",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Placement",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "name",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    }
                ]
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "offset",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "limit",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "given",
                        "args": null,
                        "storageKey": null
                    }
                ]
            }
        ]
    } as any;
})();
(node as any).hash = '9b3e2d5637e5e06a7449ec0d2c8e0d3c';
export default node;
