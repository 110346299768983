/* tslint:disable */
/* eslint-disable */
/* @relayHash f4b7d0e86c8f3c529b7bfb770454586d */

import { ConcreteRequest } from "relay-runtime";
export type TabsCoursesQueryVariables = {
    categoryUUID?: string | null;
    secondaryCategoryUUID?: string | null;
    name?: string | null;
};
export type TabsCoursesQueryResponse = {
    readonly courses: {
        readonly edges: ReadonlyArray<{
            readonly ident: number;
            readonly name: string;
            readonly price: number;
            readonly category: {
                readonly uuid: string | null;
            } | null;
            readonly secondaryCategory: {
                readonly uuid: string | null;
            } | null;
            readonly specificTerms: string | null;
        } | null> | null;
    } | null;
};
export type TabsCoursesQuery = {
    readonly response: TabsCoursesQueryResponse;
    readonly variables: TabsCoursesQueryVariables;
};



/*
query TabsCoursesQuery(
  $categoryUUID: UUID
  $secondaryCategoryUUID: UUID
  $name: String
) {
  courses(filter: {categoryUUID: $categoryUUID, secondaryCategoryUUID: $secondaryCategoryUUID, name: $name}, page: {limit: 60}, orderBy: {ascending: false, field: "priority"}) {
    edges {
      __typename
      ident: id
      name
      price
      category {
        uuid
      }
      secondaryCategory {
        uuid
      }
      specificTerms
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "categoryUUID",
            "type": "UUID",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "secondaryCategoryUUID",
            "type": "UUID",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "name",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "ObjectValue",
            "name": "filter",
            "fields": [
                {
                    "kind": "Variable",
                    "name": "categoryUUID",
                    "variableName": "categoryUUID"
                },
                {
                    "kind": "Variable",
                    "name": "name",
                    "variableName": "name"
                },
                {
                    "kind": "Variable",
                    "name": "secondaryCategoryUUID",
                    "variableName": "secondaryCategoryUUID"
                }
            ]
        } as any),
        ({
            "kind": "Literal",
            "name": "orderBy",
            "value": {
                "ascending": false,
                "field": "priority"
            }
        } as any),
        ({
            "kind": "Literal",
            "name": "page",
            "value": {
                "limit": 60
            }
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": "ident",
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "price",
        "args": null,
        "storageKey": null
    } as any), v5 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "uuid",
            "args": null,
            "storageKey": null
        } as any)
    ], v6 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "category",
        "storageKey": null,
        "args": null,
        "concreteType": "Category",
        "plural": false,
        "selections": (v5 /*: any*/)
    } as any), v7 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "secondaryCategory",
        "storageKey": null,
        "args": null,
        "concreteType": "SecondaryCategory",
        "plural": false,
        "selections": (v5 /*: any*/)
    } as any), v8 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "specificTerms",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "TabsCoursesQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "courses",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CoursePage",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": null,
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/)
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "TabsCoursesQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "courses",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CoursePage",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": null,
                            "plural": true,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "__typename",
                                    "args": null,
                                    "storageKey": null
                                },
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/)
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "TabsCoursesQuery",
            "id": null,
            "text": "query TabsCoursesQuery(\n  $categoryUUID: UUID\n  $secondaryCategoryUUID: UUID\n  $name: String\n) {\n  courses(filter: {categoryUUID: $categoryUUID, secondaryCategoryUUID: $secondaryCategoryUUID, name: $name}, page: {limit: 60}, orderBy: {ascending: false, field: \"priority\"}) {\n    edges {\n      __typename\n      ident: id\n      name\n      price\n      category {\n        uuid\n      }\n      secondaryCategory {\n        uuid\n      }\n      specificTerms\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'e240c26a6d346a0767df44dff0be990a';
export default node;
