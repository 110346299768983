/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserType = "candidate" | "manager" | "mentor" | "%future added value";
export type LoginPage_user = {
    readonly type: UserType;
    readonly " $refType": "LoginPage_user";
};
export type LoginPage_user$data = LoginPage_user;
export type LoginPage_user$key = {
    readonly " $data"?: LoginPage_user$data;
    readonly " $fragmentRefs": FragmentRefs<"LoginPage_user">;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "LoginPage_user",
    "type": "User",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "type",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = '111fb910663fcb8ed186ac35267f50ff';
export default node;
