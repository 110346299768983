import * as React from 'react';
import CoreInput from 'sharedComponents/core/Input/CoreInput';
import Icon from 'sharedComponents/core/Icon';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import classNames from 'classnames';

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 10px',
    boxShadow: theme.shadows.primary,
    borderRadius: 6,
    backgroundColor: 'white'
  }
}));

type Props = {
  onChange: (text: string) => string | void | Promise<void>;
  value?: string;
  autoFocus?: boolean;
  className?: string;
};
function SearchBar({ onChange, value, autoFocus, className }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classNames(classes.root, className)}>
      <CoreInput
        placeholder="Search"
        type="search"
        onChange={onChange}
        value={value}
        autoFocus={autoFocus}
      />
      <Icon name="SearchGlass" />
    </div>
  );
}

export default SearchBar;
