import React from 'react';
import MultiUser from 'components/MultiUser';
import { setBodyOverflow } from './helpers';

type State = {
  shown: boolean;
  courseId?: number;
};
type ActionType = 'show' | 'hide';
type Action = {
  type: ActionType;
  courseId?: number;
  delegate?: { key: string; uuid: string; value: string };
};
type Dispatch = (action: Action) => void;

const SideModalDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

function sideModalReducer(state: State, action: Action) {
  switch (action.type) {
    case 'show': {
      setBodyOverflow(false);
      return {
        shown: true,
        courseId: action.courseId,
        delegate: action.delegate
      };
    }
    case 'hide': {
      setBodyOverflow(true);
      return { shown: false, courseId: undefined, delegate: undefined };
    }
  }
}

function SideModalProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(sideModalReducer, {
    shown: false,
    courseId: undefined,
    delegate: undefined
  });
  return (
    <SideModalDispatchContext.Provider value={dispatch}>
      <MultiUser
        isOpen={state.shown}
        onClose={() => {
          dispatch({ type: 'hide' });
        }}
        initialCourse={state.courseId}
        initialUser={state.delegate}
      />
      {children}
    </SideModalDispatchContext.Provider>
  );
}

function useSideModalDispatch() {
  const context = React.useContext(SideModalDispatchContext);
  if (context === undefined) {
    throw new Error('SideModalDispatch must be used with SideModalProvider');
  }
  return context;
}

export { SideModalProvider, useSideModalDispatch };
