/* tslint:disable */
/* eslint-disable */
/* @relayHash 3d20a017dcdbe58f7d5cb85cb69e683a */

import { ConcreteRequest } from "relay-runtime";
export type UserType = "candidate" | "manager" | "mentor" | "%future added value";
export type Content_ResetRequestMutationVariables = {
    email: string;
    role: UserType;
};
export type Content_ResetRequestMutationResponse = {
    readonly resetPasswordRequest: boolean;
};
export type Content_ResetRequestMutation = {
    readonly response: Content_ResetRequestMutationResponse;
    readonly variables: Content_ResetRequestMutationVariables;
};



/*
mutation Content_ResetRequestMutation(
  $email: String!
  $role: UserType!
) {
  resetPasswordRequest(input: {email: $email, role: $role})
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "email",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "role",
            "type": "UserType!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "resetPasswordRequest",
            "args": [
                {
                    "kind": "ObjectValue",
                    "name": "input",
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "email",
                            "variableName": "email"
                        },
                        {
                            "kind": "Variable",
                            "name": "role",
                            "variableName": "role"
                        }
                    ]
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "Content_ResetRequestMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "Content_ResetRequestMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "Content_ResetRequestMutation",
            "id": null,
            "text": "mutation Content_ResetRequestMutation(\n  $email: String!\n  $role: UserType!\n) {\n  resetPasswordRequest(input: {email: $email, role: $role})\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '825d55a67a65ca77f63d5a43a2d7e6e8';
export default node;
