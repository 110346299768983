/* tslint:disable */
/* eslint-disable */
/* @relayHash d397096f1924601631434df986e08755 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type App_Holder_QueryVariables = {};
export type App_Holder_QueryResponse = {
    readonly manager: {
        readonly " $fragmentRefs": FragmentRefs<"AppHolder_manager">;
    } | null;
};
export type App_Holder_Query = {
    readonly response: App_Holder_QueryResponse;
    readonly variables: App_Holder_QueryVariables;
};



/*
query App_Holder_Query {
  manager {
    ...AppHolder_manager
    id
  }
}

fragment AppHolder_manager on Manager {
  uuid
  firstName
  lastName
  profileImageUrl
  client {
    logoURL
    id
  }
  ...ManagerModal_manager
}

fragment ManagerModal_manager on Manager {
  id
  firstName
  lastName
  email
  telephone
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "App_Holder_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "manager",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Manager",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "AppHolder_manager",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "App_Holder_Query",
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "manager",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Manager",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "uuid",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "firstName",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "lastName",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "profileImageUrl",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "client",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Client",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "logoURL",
                                    "args": null,
                                    "storageKey": null
                                },
                                (v0 /*: any*/)
                            ]
                        },
                        (v0 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "email",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "telephone",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "App_Holder_Query",
            "id": null,
            "text": "query App_Holder_Query {\n  manager {\n    ...AppHolder_manager\n    id\n  }\n}\n\nfragment AppHolder_manager on Manager {\n  uuid\n  firstName\n  lastName\n  profileImageUrl\n  client {\n    logoURL\n    id\n  }\n  ...ManagerModal_manager\n}\n\nfragment ManagerModal_manager on Manager {\n  id\n  firstName\n  lastName\n  email\n  telephone\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'ae1f32a58cdf66e3f2f3dc9abd5b102d';
export default node;
