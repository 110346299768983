/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ManagerModal_manager = {
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
    readonly telephone: string;
    readonly " $refType": "ManagerModal_manager";
};
export type ManagerModal_manager$data = ManagerModal_manager;
export type ManagerModal_manager$key = {
    readonly " $data"?: ManagerModal_manager$data;
    readonly " $fragmentRefs": FragmentRefs<"ManagerModal_manager">;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "ManagerModal_manager",
    "type": "Manager",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "telephone",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = 'd688e0bc69a6ed1f9a2af1ac22f1dd66';
export default node;
