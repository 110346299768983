/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AppHolder_manager = {
    readonly uuid: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly profileImageUrl: string | null;
    readonly client: {
        readonly logoURL: string | null;
    };
    readonly " $fragmentRefs": FragmentRefs<"ManagerModal_manager">;
    readonly " $refType": "AppHolder_manager";
};
export type AppHolder_manager$data = AppHolder_manager;
export type AppHolder_manager$key = {
    readonly " $data"?: AppHolder_manager$data;
    readonly " $fragmentRefs": FragmentRefs<"AppHolder_manager">;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "AppHolder_manager",
    "type": "Manager",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "uuid",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "profileImageUrl",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "client",
            "storageKey": null,
            "args": null,
            "concreteType": "Client",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "logoURL",
                    "args": null,
                    "storageKey": null
                }
            ]
        },
        {
            "kind": "FragmentSpread",
            "name": "ManagerModal_manager",
            "args": null
        }
    ]
} as any);
(node as any).hash = '87b3fa0f7446d3536e9e60b07023332b';
export default node;
