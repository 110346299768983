import * as React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import environment from 'api/environment';
import styled from 'styled-components';
import { CandidateSignoffPageQueryResponse } from './__generated__/CandidateSignoffPageQuery.graphql';
import SignoffRequestCard from 'components/SignoffRequestCard';
import theme from 'helpers/theme';
import moment from 'moment';
import Spacer from 'sharedComponents/core/Spacers/Spacer';

const Title = styled.div`
  font-weight: 800;
  font-size: 17px;
`;

const SignoffRequestRow = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, 300px);
  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
  padding-bottom: 20px;
`;

const ShowMoreButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

const ShowMoreButton = styled.div`
  background-color: #c6c7ca;
  color: white;
  border-radius: 6px;
  padding: 10px;
  font-size: 13px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  width: 200px;
`;

const NoneFoundMessage = styled.div`
  color: ${theme.colors.textGrey};
  text-align: center;
`;

type Props = {
  candidateID: string;
};

const PAGE_SIZE = 12;

function CandidateSignoffPage({ candidateID }: Props) {
  const [limit, setLimit] = React.useState(PAGE_SIZE);

  React.useEffect(() => {
    setLimit(PAGE_SIZE);
  }, [candidateID]);

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query CandidateSignoffPageQuery(
          $candidateID: ID
          $limit: Int!
          $offset: Int!
        ) {
          signoffRequests(
            filter: { candidateID: $candidateID }
            page: { limit: $limit, offset: $offset }
          ) {
            edges {
              id
              createdAt
              title
              PDFURL
              approval
              candidate {
                firstName
                lastName
                jobTitle
              }
              entries {
                pageInfo {
                  total
                }
              }
              placement {
                name
              }
            }
            pageInfo {
              total
              limit
              offset
            }
          }
        }
      `}
      variables={{
        candidateID: candidateID,
        limit: limit,
        offset: 0
      }}
      render={({ error, props }) => {
        if (!props) {
          return <div></div>;
        }
        const { signoffRequests } = props as CandidateSignoffPageQueryResponse;

        if (signoffRequests?.edges?.length != null) {
          if (signoffRequests?.edges?.length === 0) {
            return (
              <NoneFoundMessage>
                No sign-off requests found for this candidate.
              </NoneFoundMessage>
            );
          }
        }

        return (
          <>
            <Title>Monthly Sign-off </Title>
            <Spacer spacing={2} vertical />
            <SignoffRequestRow>
              {signoffRequests?.edges?.map((signoffRequest: any) => (
                <SignoffRequestCard
                  firstName={signoffRequest?.candidate.firstName}
                  lastName={signoffRequest?.candidate.lastName}
                  placementName={signoffRequest?.placement?.name}
                  approval={signoffRequest?.approval}
                  entriesLength={signoffRequest?.entries?.pageInfo.total || '0'}
                  createdAt={
                    moment(signoffRequest?.createdAt).format('DD MMM YYYY') ||
                    ''
                  }
                  title={signoffRequest?.title}
                  PDFURL={signoffRequest?.PDFURL || ''}
                  supervisorSignOffPage={false}
                  signoffRequestID={signoffRequest?.id}
                  onApprovalSignoff = {null}
                />
              ))}
            </SignoffRequestRow>
            {(signoffRequests?.pageInfo?.total ?? 0) >
              (signoffRequests?.edges?.length ?? 0) && (
              <ShowMoreButtonContainer>
                <ShowMoreButton onClick={() => setLimit(limit + PAGE_SIZE)}>
                  Show More
                </ShowMoreButton>
              </ShowMoreButtonContainer>
            )}
          </>
        );
      }}
    />
  );
}

export default CandidateSignoffPage;
