/* tslint:disable */
/* eslint-disable */
/* @relayHash 444602c4b8248ab0a3674ba68e95fcbd */

import { ConcreteRequest } from "relay-runtime";
export type MultiUser_fetch1_QueryVariables = {
    courseID: number;
};
export type MultiUser_fetch1_QueryResponse = {
    readonly course: {
        readonly ident: number;
        readonly name: string;
        readonly price: number;
        readonly specificTerms: string | null;
    } | null;
    readonly manager: {
        readonly firstName: string;
        readonly lastName: string;
        readonly client: {
            readonly isContract: boolean;
        };
    } | null;
    readonly categories: {
        readonly edges: ReadonlyArray<{
            readonly name: string;
            readonly uuid: string | null;
            readonly classroomOnly: boolean;
            readonly secondaryCategories: ReadonlyArray<{
                readonly uuid: string | null;
                readonly name: string;
            } | null> | null;
        } | null> | null;
    } | null;
};
export type MultiUser_fetch1_Query = {
    readonly response: MultiUser_fetch1_QueryResponse;
    readonly variables: MultiUser_fetch1_QueryVariables;
};



/*
query MultiUser_fetch1_Query(
  $courseID: Int!
) {
  course(id: $courseID) {
    __typename
    ident: id
    name
    price
    specificTerms
  }
  manager {
    firstName
    lastName
    client {
      isContract
      id
    }
    id
  }
  categories {
    edges {
      name
      uuid
      classroomOnly
      secondaryCategories {
        uuid
        name
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "courseID",
            "type": "Int!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "courseID"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": "ident",
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "price",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "specificTerms",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "lastName",
        "args": null,
        "storageKey": null
    } as any), v8 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "isContract",
        "args": null,
        "storageKey": null
    } as any), v9 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "uuid",
        "args": null,
        "storageKey": null
    } as any), v10 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "categories",
        "storageKey": null,
        "args": null,
        "concreteType": "CategoryPage",
        "plural": false,
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "Category",
                "plural": true,
                "selections": [
                    (v3 /*: any*/),
                    (v9 /*: any*/),
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "classroomOnly",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "secondaryCategories",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SecondaryCategory",
                        "plural": true,
                        "selections": [
                            (v9 /*: any*/),
                            (v3 /*: any*/)
                        ]
                    }
                ]
            }
        ]
    } as any), v11 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "MultiUser_fetch1_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "course",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/)
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "manager",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Manager",
                    "plural": false,
                    "selections": [
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "client",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Client",
                            "plural": false,
                            "selections": [
                                (v8 /*: any*/)
                            ]
                        }
                    ]
                },
                (v10 /*: any*/)
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "MultiUser_fetch1_Query",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "course",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "__typename",
                            "args": null,
                            "storageKey": null
                        },
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/)
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "manager",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Manager",
                    "plural": false,
                    "selections": [
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "client",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Client",
                            "plural": false,
                            "selections": [
                                (v8 /*: any*/),
                                (v11 /*: any*/)
                            ]
                        },
                        (v11 /*: any*/)
                    ]
                },
                (v10 /*: any*/)
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "MultiUser_fetch1_Query",
            "id": null,
            "text": "query MultiUser_fetch1_Query(\n  $courseID: Int!\n) {\n  course(id: $courseID) {\n    __typename\n    ident: id\n    name\n    price\n    specificTerms\n  }\n  manager {\n    firstName\n    lastName\n    client {\n      isContract\n      id\n    }\n    id\n  }\n  categories {\n    edges {\n      name\n      uuid\n      classroomOnly\n      secondaryCategories {\n        uuid\n        name\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '082e26d4da066200b70652080e67adec';
export default node;
