import React from 'react';
import styled from 'styled-components';
import Spacer from 'sharedComponents/core/Spacers/Spacer';
import ProgressBar from 'sharedComponents/core/ProgressBar';

const ProgressBarCont = styled.div`
  display: flex;
  align-items: center;
`;

const ProgressText = styled.div`
  font-size: 12px;
`;

type Props = {
  areaProgress: any;
};

function PracticeAreaProgress({ areaProgress }: Props) {
  let percent = 0;
  let totalPracticeSkillsDone = 0;
  let totalPracticeSkillsRemaining = 0;

  areaProgress?.map((practiceArea: any) => {
    if (
      practiceArea.practiceSkillsDone &&
      practiceArea.practiceSkillsDone.length > 0
    ) {
      totalPracticeSkillsDone += practiceArea.practiceSkillsDone.length;
    }


    if (
      practiceArea.practiceSkillsRemaining &&
      practiceArea.practiceSkillsRemaining.length > 0
    ) {
      totalPracticeSkillsRemaining +=
        practiceArea.practiceSkillsRemaining.length;
    }

    if (totalPracticeSkillsDone === 0 && totalPracticeSkillsRemaining === 0) {
      percent = 0;
    } else {
      percent =
        (totalPracticeSkillsDone / 10 /
          (totalPracticeSkillsDone + totalPracticeSkillsRemaining)) *
        100;
      percent = Math.round(percent);
    }
  
  });


  return (
    <div>
      <Spacer vertical spacing={1} />
      <ProgressBarCont>
        <ProgressText>{percent}%</ProgressText>
        <Spacer horizontal spacing={1} />
        <ProgressBar
          percent={percent}
          sliderFirstColor="rgb(243, 122, 33)"
          sliderSecondColor="rgb(255, 163, 96)"
        />
      </ProgressBarCont>
    </div>
  );
}

export default PracticeAreaProgress;
