import * as React from 'react';
import SideModal from 'sharedComponents/SideModal/SideModal';
import Tabs from 'sharedComponents/SideModal/Tabs';
import { tabList } from './Tabs';
import { graphql, QueryRenderer } from 'react-relay';
import environment from 'api/environment';
import { MultiUser_fetch1_QueryResponse } from './__generated__/MultiUser_fetch1_Query.graphql';
import { MultiUser_fetch2_QueryResponse } from './__generated__/MultiUser_fetch2_Query.graphql';

type Props = {
  manager?:
    | MultiUser_fetch1_QueryResponse['manager']
    | MultiUser_fetch2_QueryResponse['manager'];
  course: MultiUser_fetch1_QueryResponse['course'];
  categories: MultiUser_fetch1_QueryResponse['categories'];
} & InputProps;

const MultipleUsers = ({
  isOpen,
  onClose,
  course,
  manager,
  categories,
  initialUser
}: Props) => {
  return (
    <SideModal
      isOpen={isOpen}
      title="Course Management"
      closeModal={() => onClose()}
    >
      <Tabs
        content={tabList(manager?.client.isContract ?? false)}
        closeModal={() => onClose()}
        initialState={{
          courses: course ? [course] : [],
          users: [initialUser] ?? [undefined],
          categories: categories ? categories : { edges: [] }
        }}
        disableSwitching
      />
    </SideModal>
  );
};

type InputProps = {
  isOpen: boolean;
  onClose: Function;
  initialCourse?: number;
  initialUser?: { key: string; uuid: string; value: string };
};

export default (multiUserProps: InputProps) => {
  const queryCourse = multiUserProps.initialCourse
    ? graphql`
        query MultiUser_fetch1_Query($courseID: Int!) {
          course(id: $courseID) {
            ident: id
            name
            price
            specificTerms
          }
          manager {
            firstName
            lastName
            client {
              isContract
            }
          }
          categories {
            edges {
              name
              uuid
              classroomOnly
              secondaryCategories {
                uuid
                name
              }
            }
          }
        }
      `
    : graphql`
        query MultiUser_fetch2_Query {
          manager {
            firstName
            lastName
            client {
              isContract
            }
          }
          categories {
            edges {
              name
              uuid
              classroomOnly
              secondaryCategories {
                uuid
                name
              }
            }
          }
        }
      `;

  return (
    <QueryRenderer
      environment={environment}
      query={queryCourse}
      variables={{
        courseID: multiUserProps.initialCourse,
        shouldRefetch: multiUserProps.isOpen
      }}
      render={({ error, props }: any) => {
        return <MultipleUsers {...multiUserProps} {...props} />;
      }}
    />
  );
};
