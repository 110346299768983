/* tslint:disable */
/* eslint-disable */
/* @relayHash fcb505cf42254d80c1964970d76b2899 */

import { ConcreteRequest } from "relay-runtime";
export type SignoffRequestCardMutationVariables = {
    signoffRequestID: string;
};
export type SignoffRequestCardMutationResponse = {
    readonly signoffTokenlessApproval: boolean;
};
export type SignoffRequestCardMutation = {
    readonly response: SignoffRequestCardMutationResponse;
    readonly variables: SignoffRequestCardMutationVariables;
};



/*
mutation SignoffRequestCardMutation(
  $signoffRequestID: ID!
) {
  signoffTokenlessApproval(input: {signoffRequestID: $signoffRequestID})
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "signoffRequestID",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "signoffTokenlessApproval",
            "args": [
                {
                    "kind": "ObjectValue",
                    "name": "input",
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "signoffRequestID",
                            "variableName": "signoffRequestID"
                        }
                    ]
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "SignoffRequestCardMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "SignoffRequestCardMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "SignoffRequestCardMutation",
            "id": null,
            "text": "mutation SignoffRequestCardMutation(\n  $signoffRequestID: ID!\n) {\n  signoffTokenlessApproval(input: {signoffRequestID: $signoffRequestID})\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '5ada5a5c4aaccb72778c1cb45ea54fcb';
export default node;
