/* tslint:disable */
/* eslint-disable */
/* @relayHash f6d9e06b560cb2b231fdd342149fa2b9 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseStatus = "complete" | "failed" | "incomplete" | "%future added value";
export type CandidateCoursesPageQueryVariables = {
    uuid?: string | null;
    offset?: number | null;
    limit?: number | null;
};
export type CandidateCoursesPageQueryResponse = {
    readonly candidate: {
        readonly id: string;
        readonly firstName: string;
        readonly myCourses: ReadonlyArray<{
            readonly status: CourseStatus;
            readonly certificateURL: string | null;
            readonly certificateName: string | null;
            readonly course: {
                readonly ident: number;
                readonly name: string;
                readonly category: {
                    readonly name: string;
                } | null;
            } | null;
            readonly progress: {
                readonly total: number;
                readonly completed: number;
                readonly percent: number;
            } | null;
        }> | null;
        readonly activity: {
            readonly " $fragmentRefs": FragmentRefs<"ActivityTable_activity">;
        } | null;
    } | null;
};
export type CandidateCoursesPageQuery = {
    readonly response: CandidateCoursesPageQueryResponse;
    readonly variables: CandidateCoursesPageQueryVariables;
};



/*
query CandidateCoursesPageQuery(
  $uuid: UUID
  $offset: Int
  $limit: Int
) {
  candidate(uuid: $uuid) {
    id
    firstName
    myCourses {
      status
      certificateURL
      certificateName
      course {
        __typename
        ident: id
        name
        category {
          name
        }
      }
      progress {
        total
        completed
        percent
      }
    }
    activity(page: {limit: $limit, offset: $offset}) {
      ...ActivityTable_activity
    }
  }
}

fragment ActivityTable_activity on ActivityPage {
  edges {
    type
    createdAt
    course {
      __typename
      ident: id
      name
    }
  }
  pageInfo {
    total
    limit
    offset
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "uuid",
            "type": "UUID",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "offset",
            "type": "Int",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "limit",
            "type": "Int",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "uuid",
            "variableName": "uuid"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "certificateURL",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "certificateName",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "ScalarField",
        "alias": "ident",
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v8 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any), v9 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "category",
        "storageKey": null,
        "args": null,
        "concreteType": "Category",
        "plural": false,
        "selections": [
            (v8 /*: any*/)
        ]
    } as any), v10 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "total",
        "args": null,
        "storageKey": null
    } as any), v11 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "progress",
        "storageKey": null,
        "args": null,
        "concreteType": "Progress",
        "plural": false,
        "selections": [
            (v10 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "completed",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "percent",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v12 = [
        ({
            "kind": "ObjectValue",
            "name": "page",
            "fields": [
                {
                    "kind": "Variable",
                    "name": "limit",
                    "variableName": "limit"
                },
                {
                    "kind": "Variable",
                    "name": "offset",
                    "variableName": "offset"
                }
            ]
        } as any)
    ], v13 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "__typename",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "CandidateCoursesPageQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "candidate",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Candidate",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "myCourses",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "MyCourse",
                            "plural": true,
                            "selections": [
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "course",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": null,
                                    "plural": false,
                                    "selections": [
                                        (v7 /*: any*/),
                                        (v8 /*: any*/),
                                        (v9 /*: any*/)
                                    ]
                                },
                                (v11 /*: any*/)
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "activity",
                            "storageKey": null,
                            "args": (v12 /*: any*/),
                            "concreteType": "ActivityPage",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "FragmentSpread",
                                    "name": "ActivityTable_activity",
                                    "args": null
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "CandidateCoursesPageQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "candidate",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Candidate",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "myCourses",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "MyCourse",
                            "plural": true,
                            "selections": [
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "course",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": null,
                                    "plural": false,
                                    "selections": [
                                        (v13 /*: any*/),
                                        (v7 /*: any*/),
                                        (v8 /*: any*/),
                                        (v9 /*: any*/)
                                    ]
                                },
                                (v11 /*: any*/)
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "activity",
                            "storageKey": null,
                            "args": (v12 /*: any*/),
                            "concreteType": "ActivityPage",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "edges",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Activity",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "type",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "createdAt",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "course",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": null,
                                            "plural": false,
                                            "selections": [
                                                (v13 /*: any*/),
                                                (v7 /*: any*/),
                                                (v8 /*: any*/)
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "pageInfo",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "PageInfo",
                                    "plural": false,
                                    "selections": [
                                        (v10 /*: any*/),
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "limit",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "offset",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "CandidateCoursesPageQuery",
            "id": null,
            "text": "query CandidateCoursesPageQuery(\n  $uuid: UUID\n  $offset: Int\n  $limit: Int\n) {\n  candidate(uuid: $uuid) {\n    id\n    firstName\n    myCourses {\n      status\n      certificateURL\n      certificateName\n      course {\n        __typename\n        ident: id\n        name\n        category {\n          name\n        }\n      }\n      progress {\n        total\n        completed\n        percent\n      }\n    }\n    activity(page: {limit: $limit, offset: $offset}) {\n      ...ActivityTable_activity\n    }\n  }\n}\n\nfragment ActivityTable_activity on ActivityPage {\n  edges {\n    type\n    createdAt\n    course {\n      __typename\n      ident: id\n      name\n    }\n  }\n  pageInfo {\n    total\n    limit\n    offset\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '485c188dda54952caccdd7ded64758c0';
export default node;
