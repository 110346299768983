import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import UserSearch from 'components/UserSearch';
import theme, { Theme } from 'helpers/theme';
import PageHeader from 'components/PageHeader';
import Table from 'sharedComponents/core/Table';
import UserLabel from 'sharedComponents/core/Table/UserLabel';
import Text from 'sharedComponents/core/Table/Text/Text';
import Spacer from 'sharedComponents/core/Spacers/Spacer';
import Paginator, { Page } from 'sharedComponents/Pagination/Paginator';
import { createFragmentContainer, graphql } from 'react-relay';
import { Router, useRouter } from 'found';
import { CandidatesPage_candidates } from './__generated__/CandidatesPage_candidates.graphql';
import { CandidatesPage_manager } from './__generated__/CandidatesPage_manager.graphql';
import { fetchQuery } from 'relay-runtime';
import environment from 'api/environment';
import { CandidatesPageQueryResponse } from './__generated__/CandidatesPageQuery.graphql';
import PracticeAreaProgress from './PracticeAreaProgress';

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: 1275
  },
  searchAndFilter: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 40
  },
  tableOptions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dropdown: {
    background: 'white'
  },
  dropdownOption: {
    display: 'flex',
    background: 'white',
    width: '200px'
  },
  divider: {
    width: theme.spacing(1)
  },
  search: {
    flex: 0.4
  },
  actionsRow: {
    borderLeft: '1px solid #ededed',
    paddingLeft: 23,
    height: 38,
    display: 'flex',
    /* justify-content: center; */
    alignItems: 'center'
  },
  checkBox: {
    marginLeft: 0,
    marginRight: '10px',
    cursor: 'pointer',
    transform: 'scale(1.2)'
  }
}));

const candidateRow = (
  userUUID: string,
  name: string,
  profileUrl: string | undefined,
  email: string,
  lastActiveTimestamp: string,
  nextExpiryTimestamp: string,
  areaProgress : any,
  classes: any,
  router: any
): any => {
  return {
    key: userUUID,
    cells: [
      { component: () => <UserLabel name={name} profileUrl={profileUrl} /> },
      { component: () => <Text text={email} color={theme.colors.textOrange} /> },
      { component: () => <Text text={lastActiveTimestamp} formatDate /> },
      { component: () => <PracticeAreaProgress areaProgress={areaProgress}/>  }
    ],
    onClick: () => router.push(`/app/trainee/${userUUID}`)
  };
};

type Props = {
  candidates: CandidatesPage_candidates;
  manager: CandidatesPage_manager;
  router: Router;
};

const CandidatesPage = ({ candidates, manager }: Props) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const { router, match } = useRouter();

  const rows = candidates.edges ?? [];
  const pageInfo = {
    total: candidates.pageInfo?.total ?? 0,
    limit: candidates.pageInfo?.limit ?? 10,
    offset: candidates.pageInfo?.offset ?? 0
  };
  const page = {
    currentPage: Math.ceil(pageInfo.offset / pageInfo.limit) + 1,
    numPages: Math.ceil(pageInfo.total / pageInfo.limit)
  };

  const candidateComponents = rows.map((candidate: any) =>
    candidateRow(
      candidate?.uuid,
      `${candidate?.firstName} ${candidate?.lastName}`,
      '',
      candidate?.email,
      candidate?.lastLogin,
      candidate?.nextExpiry,
      candidate?.areaProgress,
      classes,
      router
    )
  );
  
  const onUpdatePage = ({ offset, limit }: Page) => {
    if (offset <= 0) {
      offset = 1;
    }
    router.push(
      `/app?offset=${(offset - 1) * limit}&limit=${limit}`
    );
  };

  return (
    <div className={classes.root}>
      <PageHeader
        showCreateButtons
        title={manager.client.name}
        subTitle=""
        sideText={`${pageInfo?.total} members total`}
      />
      <div className={classes.searchAndFilter}>
        <div className={classes.search}>
          <UserSearch
            clientName={manager.client.name}
            searchFunction={async (text: string) => {
              const query = graphql`
                query CandidatesPageQuery($name: String!) {
                  candidates(filter: { name: $name }, page: { limit: 8 }) {
                    edges {
                      id
                      email
                      firstName
                      lastName
                    }
                  }
                }
              `;

              const variables = {
                name: text
              };

              const data = (await fetchQuery(
                environment,
                query,
                variables
              )) as CandidatesPageQueryResponse;

              if (!data || !data.candidates || !data.candidates.edges) {
                console.error('Could not get data', data);
                return [];
              }

              const results = data.candidates?.edges.map((candidate) => ({
                id: candidate?.id ?? '',
                key: `${candidate?.firstName} ${candidate?.lastName}`,
                value: candidate?.email ?? ''
              }));

              return results;
            }}
          />
        </div>
      </div>
      <Spacer spacing={3} vertical />
      <Table
        header={['Name', 'Email', 'Last Active', 'Competencies']}
        rows={candidateComponents}
      />
      <Spacer vertical spacing={3} />
      <Paginator
        currentPage={page.currentPage}
        updatePage={onUpdatePage}
        numPages={page.numPages}
        itemsPerPage={pageInfo.limit}
        showRange={page.numPages} //{page.numPages > 4 ? 4 : page.numPages}
      />
    </div>
  );
};

const CandidatesPageFrag = createFragmentContainer(CandidatesPage, {
  candidates: graphql`
    fragment CandidatesPage_candidates on CandidatePage {
      edges {
        uuid
        email
        firstName
        lastName
        lastLogin
        createdAt
        areaProgress {
          practiceArea {
            id
            name
          }
          practiceSkillsDone {
            id
          }
          practiceSkillsRemaining {
            id
    
          }
        }
      }
      pageInfo {
        total
        offset
        limit
        given
      }
    }
  `,
  manager: graphql`
    fragment CandidatesPage_manager on Manager {
      client {
        name
      }
    }
  `
});

export default CandidatesPageFrag;
