import * as React from 'react';
import HeaderMenu, { Option } from 'components/Menu/HeaderMenu';
import SideMenu from 'components/Menu/SideMenu';
import { Tab } from 'components/Menu/SideMenu/SideMenu';
import { createUseStyles } from 'react-jss';
import { useRouter } from 'found';
import { createFragmentContainer, graphql } from 'react-relay';

import type { AppHolder_manager } from './__generated__/AppHolder_manager.graphql';
import { Logout } from 'helpers/logout';
import { getCourse } from './helpers';
import { useSideModalDispatch } from './SideModalProvider';
import ManagerModal from 'components/ManagerSlideIn';

type Props = {
  children?: React.ReactChildren;
  manager: AppHolder_manager;
  retry: () => void;
};

const useStyles = createUseStyles(() => ({
  appHolder: {
    display: 'flex',
    padding: '42px 60px',
    justifyContent: 'center',
    gridArea: 'content',
    overflowY: 'auto'
  },
  grid: {
    display: 'grid',
    gridTemplateAreas: `
    "side header"
    "side content"
    `,
    gridTemplateRows: '82px 1fr',
    gridTemplateColumns: '307px 1fr',
    height: '100vh'
  }
}));

const AppHolder = ({ children, manager, retry }: Props) => {
  const classes = useStyles();
  const { match, router } = useRouter();
  const tabs: Tab[] = [
    {
      id: 0,
      icon: 'LeftNav_Icon_Delegates',
      title: 'Trainees',
      size: 28
    },
    {
      id: 1,
      icon: 'LeftNav_Icon_Courses',
      title: 'Supervisor Sign-off',
      size: 22
    }
  ];

  const [openManagerSlideIn, setOpenManagerSlideIn] = React.useState(false);
  const dispatchModal = useSideModalDispatch();

  const options: Option[] = [
    {
      id: '1',
      title: 'Logout',
      onClick: () => {
        Logout(
          () => {},
          () => {
            router.push('/login');
          }
        );
      }
    },
    {
      id: '2',
      title: 'My Account',
      onClick: () => setOpenManagerSlideIn((previous) => !previous)
    },
  ];

  if (manager.uuid === '00000000-0000-0000-0000-000000000000') {
    router.push('/login');
    Logout(
      () => {},
      () => {}
    );
  }

  const selected = () => {
    const { routes } = match;
    const currentRouter = routes[routes.length - 1];
    switch (currentRouter.path) {
      case '/':
        return tabs[0];
      case '/trainee/:id':
        return tabs[0];
      case '/signoffrequests':
        return tabs[1];
      default:
        return tabs[0];
    }
  };

  if (match.location.query.course) {
    console.log('open course', match.location.query.course);

    try {
      var courseID = parseInt(match.location.query.course);
      // Get course info
      dispatchModal({
        type: 'show',
        courseId: courseID
      });
    } catch (err) {
      console.warn(err);
    }
  }

  return (
    <div className={classes.grid}>
      <HeaderMenu
        logo={manager.client?.logoURL ?? undefined}
        user={{
          name: `${manager.firstName} ${manager.lastName}`,
          url: manager?.profileImageUrl || undefined
        }}
        options={options}
      />
      <SideMenu
        tabs={tabs}
        selected={selected()}
        onClick={(tab) => {
          //console.log('tab', tab);
          switch (tab.id) {
            case 0:
              router.push('/app');
              break;
            case 1:
              router.push('/app/signoffrequests');
              break;
            default:
              break;
          }
        }}
      />
      <ManagerModal
        isOpen={openManagerSlideIn}
        onClose={() => setOpenManagerSlideIn(false)}
        manager={manager}
      />
      <div className={classes.appHolder}>{children}</div>
    </div>
  );
};

export default createFragmentContainer(AppHolder, {
  manager: graphql`
    fragment AppHolder_manager on Manager {
      uuid
      firstName
      lastName
      profileImageUrl
      client {
        logoURL
      }
      ...ManagerModal_manager
    }
  `
});
