import React, { useEffect } from 'react';

import { Theme } from 'helpers/theme';
import environment from 'api/environment';
import { createUseStyles, useTheme } from 'react-jss';
import { commitMutation, graphql } from 'react-relay';
import { GraphError } from 'types/general';
import { Router, useRouter } from 'found';
import Spacer from 'sharedComponents/core/Spacers/Spacer';
import Button from 'sharedComponents/core/Input/Button';

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 24,
    color: theme.colors.secondaryOrange,
    flexDirection: 'column'
  },
  approveSubTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 18,
    color: '#a9aaab',
    padding: '10px',
    fontWeight: 300
  },
  logo: {
    height: 58
  },
  approveTitle: {
    fontWeight: 700
  }
}));

const mutation = graphql`
  mutation SignoffApprovalMutation($token: String!) {
    signoffApproval(input: { token: $token }) {
      token
    }
  }
`;
const mutation2 = graphql`
  mutation SignoffApprovalInfoMutation($token: String!) {
    signoffApprovalInfo(input: { token: $token }) {
      id
      title
      candidate {
        id 
        firstName
        lastName
        email
      }
      entries {
        edges {
            id
            title
        }
        pageInfo {
          total
          offset
          limit
        }
      } 
      placement {
        id
        name
        email
      }
    }
  }
`;

const getApprovalInfo = (token: String) => {
  const variables = {
    token
  };

  return new Promise((r, j) => {
    commitMutation(environment, {
      mutation: mutation2,
      variables: variables,
      onCompleted: (resp, errors: GraphError[]) => {
        if (errors) {
          // Display error
          console.log('Errors: ', errors);
          j(`${errors[0]?.extensions?.message}`);
          return;
        }
        r(true)
      },
      onError: (err: any) => {
        j('unable to process');
      }
    });
  })
}
const attemptApproval = (router: Router, token: String) => (
  errorCallback: (err: string) => void
) => {
  const variables = {
    token
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (resp, errors: GraphError[]) => {
      if (errors) {
        // Display error
        console.log('Errors: ', errors);
        errorCallback(`${errors[0]?.extensions?.message}`);
        return;
      }
    },
    onError: (err: any) => {
      errorCallback('unable to process');
    }
  });
};

const SignoffApproval = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { router, match } = useRouter();
  const [error, setError] = React.useState('');
  const { token } = match?.params;
  const [confirmRequest, setConfirmRequest] = React.useState<Boolean | null>(null);
 
  React.useEffect(() => { 
    if (token === null || token === '') {
      setConfirmRequest(false)
      return 
    }
   getApprovalInfo(token).then(()=> {
      setConfirmRequest(true)
   }).catch((e)=> {
    setConfirmRequest(false)
   })
  }, [token]);

 useEffect(()=> {
  if (confirmRequest == null) {
    return
  }

  if (confirmRequest) {
    window.location.href = '/app/signoffrequests?token='+token;
  } else { 
  }
 }, [confirmRequest])
  return (
    <>
      {/* <RedirectRequest/> */}
      <div className={classes.root}>
        <img
          src={require('../../assets/logo/accutrainee-logo.svg')}
          className={classes.logo}
        />
        <Spacer vertical spacing={3} />
        { confirmRequest == false && (
          <div className={classes.approveTitle}>
            Unable to approve request - please log into the platform
          </div>
        )  
      }
      </div>
    </>
  );
};

export default SignoffApproval;
