/* tslint:disable */
/* eslint-disable */
/* @relayHash 93ae15930d6043378a78af682f88c594 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type App_CandidatePage_QueryVariables = {
    uuid?: string | null;
};
export type App_CandidatePage_QueryResponse = {
    readonly candidate: {
        readonly " $fragmentRefs": FragmentRefs<"CandidatePage_candidate">;
    } | null;
};
export type App_CandidatePage_Query = {
    readonly response: App_CandidatePage_QueryResponse;
    readonly variables: App_CandidatePage_QueryVariables;
};



/*
query App_CandidatePage_Query(
  $uuid: UUID
) {
  candidate(uuid: $uuid) {
    ...CandidatePage_candidate
    id
  }
}

fragment CandidatePage_candidate on Candidate {
  id
  firstName
  lastName
  email
  placements {
    edges {
      id
      ...PlacementCard_placement
    }
  }
}

fragment PlacementCard_placement on Placement {
  id
  name
  approved
  startDate
  endDate
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "uuid",
            "type": "UUID",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "uuid",
            "variableName": "uuid"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "App_CandidatePage_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "candidate",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Candidate",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "CandidatePage_candidate",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "App_CandidatePage_Query",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "candidate",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Candidate",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "firstName",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "lastName",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "email",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "placements",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PlacementPage",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "edges",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Placement",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "name",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "approved",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "startDate",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "endDate",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "App_CandidatePage_Query",
            "id": null,
            "text": "query App_CandidatePage_Query(\n  $uuid: UUID\n) {\n  candidate(uuid: $uuid) {\n    ...CandidatePage_candidate\n    id\n  }\n}\n\nfragment CandidatePage_candidate on Candidate {\n  id\n  firstName\n  lastName\n  email\n  placements {\n    edges {\n      id\n      ...PlacementCard_placement\n    }\n  }\n}\n\nfragment PlacementCard_placement on Placement {\n  id\n  name\n  approved\n  startDate\n  endDate\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '2510a839c196dbdaefd20f065b9f2b17';
export default node;
