import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import classNames from 'classnames';
import { createFragmentContainer, graphql } from 'react-relay';

import { Theme } from 'helpers/theme';
import { Router } from 'found';

type Props = {
  router: Router;
};

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: 1275
  }
}));

const CoursesPageComp = ({ router }: Props) => {
  return <div></div>;
};

const CoursesPage = createFragmentContainer(CoursesPageComp, {});

export default CoursesPage;
