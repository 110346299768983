import * as React from 'react';
import PageTitle from 'components/PageTitle';
import Button from 'sharedComponents/core/Input/Button';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import { useSideModalDispatch } from 'views/SideModalProvider';

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    display: 'flex'
  },
  infoHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  mainButtons: {
    display: 'inline-grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(2)
  }
}));

type Props = {
  title: string;
  subTitle: string;
  sideText?: string;
  sideComponent?: React.ReactNode;
  showCreateButtons: boolean;
  backProps?: {
    text: string;
    onClick: Function;
  };
};
const PageHeader = ({
  title,
  subTitle,
  showCreateButtons,
  sideText,
  sideComponent,
  backProps
}: Props) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.infoHeader}>
      <PageTitle
        title={title}
        subTitle={subTitle}
        sideText={sideText}
        sideComponent={sideComponent}
        backProps={backProps}
      />
      {showCreateButtons && (
        <div className={classes.mainButtons}>
          {/* <Button bold archetype="submit" onClick={openSideModal}>
            Quick Booking
          </Button> */}
        </div>
      )}
    </div>
  );
};

export default PageHeader;
