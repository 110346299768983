import * as React from 'react';
import { createUseStyles, jss, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import { commitMutation, createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import SignoffRequestCard from 'components/SignoffRequestCard';
import moment from 'moment';
import PageTitle from 'components/PageTitle';
import { SupervisorSignOffPage_signoffRequests } from './__generated__/SupervisorSignOffPage_signoffRequests.graphql';
import { Router, useRouter } from 'found';
import Spacer from 'sharedComponents/core/Spacers/Spacer';
import environment from 'api/environment';
import { GraphError } from 'types/general';
import { SupervisorSignOffPageInfoMutationResponse } from './__generated__/SupervisorSignOffPageInfoMutation.graphql';
import GenericPopup from 'components/core/GenericPopup/GenericPopup';
import Button from 'sharedComponents/core/Input/Button';
import { useToastDispatch } from 'components/Toast/ToastProvider';

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: 1275
  },
  requestsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  monthText: {
    color: theme.colors.primaryBlack,
    fontSize: theme.fontSizes.large,
    fontWeight: 800
  }
}));

const SignoffRequestRow = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, 300px);
  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
  padding-bottom: 20px;
`;

const ShowMoreButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;
const ButtonConfirmContainer = styled.div`
  display: flex; 
  gap: 20px;
`;

const ConfirmContent = styled.div`
  font-size: 18px; 
  padding-bottom: 40px;
`;
const ShowMoreButton = styled.div`
  background-color: #c6c7ca;
  color: white;
  border-radius: 6px;
  padding: 10px;
  font-size: 13px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  width: 200px;
`;

type MonthlySignoffRequests = {
  month: string;
  requests: any;
};

// prepares monthly signoff requests
const prepareMonthlySignoffRequests = (
  allSignOffRequests: any,
  uniqueCreatedAtValues: any
): MonthlySignoffRequests[] => {
  const monthlySignoffRequests: MonthlySignoffRequests[] = [];

  uniqueCreatedAtValues.forEach((createdAtValue: any) => {
    const signOffRequestsByMonth = getSignOffRequestsByMonth(
      allSignOffRequests,
      createdAtValue
    );
    monthlySignoffRequests.push({
      month: createdAtValue,
      requests: signOffRequestsByMonth
    });
  });

  return monthlySignoffRequests;
};

// filters signoff requests by given date (created at value)
const getSignOffRequestsByMonth = (array: any, createdAtValue: any) => {
  return array.filter(
    (element: any) => getFormattedDate(element) === createdAtValue
  );
};

const getFormattedDate = (signoffRequest: any) => {
  return moment(signoffRequest?.createdAt).format('MMM YYYY');
};

type Props = {
  signoffRequests: SupervisorSignOffPage_signoffRequests;
  router: Router;
};


export const PAGE_SIZE = 20;


const mutation = graphql`
  mutation SupervisorSignOffPageMutation($token: String!) {
    signoffApproval(input: { token: $token }) {
      token
    }
  }
`;
const mutation3 = graphql`
  mutation SupervisorSignOffPageApproveMutation($signoffRequestID: ID!) {
    signoffTokenlessApproval(input: { signoffRequestID: $signoffRequestID })
  }
`;

const mutation2 = graphql`
  mutation SupervisorSignOffPageInfoMutation($token: String!) {
    signoffApprovalInfo(input: { token: $token }) {
      id
      title
      approval
      candidate {
        id 
        firstName
        lastName
        email
      }
      entries {
        edges {
            id
            title
        }
        pageInfo {
          total
          offset
          limit
        }
      } 
      placement {
        id
        name
        email
      }
    }
  }
`;

const getApprovalInfo = (token: String): Promise<SupervisorSignOffPageInfoMutationResponse> => {
  const variables = {
    token
  };

  return new Promise((r, j) => {
    commitMutation(environment, {
      mutation: mutation2,
      variables: variables,
      onCompleted: (resp: SupervisorSignOffPageInfoMutationResponse, errors: GraphError[]) => {
        if (errors) {
          // Display error
          console.log('Errors: ', errors);
          j(`${errors[0]?.extensions?.message}`);
          return;
        }
        r(resp)
      },
      onError: (err: any) => {
        j('unable to process');
      }
    });
  })
}

const attemptApproval = (signoffRequestID: string): Promise<Boolean> =>  {

  const variables = {
    signoffRequestID: signoffRequestID
  };

  return new Promise((r, j) => {
    commitMutation(environment, {
      mutation: mutation3,
      variables,
      onCompleted: (resp, errors: GraphError[]) => {
        if (errors) {
          // Display error
          j(errors[0].message)
          return;
        } else {
           r(true)
        }
      },
      onError: (err: any) => {
         j(err)
      }
    }); 
  })
};
const attemptApprovalToken = (token: string): Promise<Boolean> =>  {
  return new Promise((r, j) => {
    const variables = {
      token
    };
  
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (resp, errors: GraphError[]) => {
        if (errors) {
          // Display error
          console.log('Errors: ', errors);
          j(`${errors[0]?.extensions?.message}`);
          return;
        }
        r(true)
      },
      onError: (err: any) => {
        j('unable to process');
      }
    });
  })
};

const SupervisorSignOffPage = ({ signoffRequests }: Props) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { router, match } = useRouter(); 
  const [limit, setLimit] = React.useState(PAGE_SIZE); 
  const allSignOffRequests = signoffRequests.edges ?? [];
  const token = match.location.query["token"]
  const dispatch = useToastDispatch();
 

  const [isOpenConfirm, setOpenConfirm] = React.useState<any>(null)
  // get unique "created at" values for showing signoff requests by month, such as "March 2021", "April 2021"
  const allCreatedAtValues = allSignOffRequests.map(
    (x: any) => getFormattedDate(x) || ''
  );
  let uniqueCreatedAtValues = Array.from(new Set(allCreatedAtValues));

 
  React.useEffect(() => {
    setLimit(PAGE_SIZE);
  }, []);

  // get monthly signoff requests
  const monthlySignoffRequests = prepareMonthlySignoffRequests(
    allSignOffRequests,
    uniqueCreatedAtValues
  );

  React.useEffect(()=> {
    if (token == null || token == "") {
      return
    }

    getApprovalInfo(token).then((result)=> { 
      let reqInfo = result.signoffApprovalInfo

      let isApproved = reqInfo?.approval || false
      if (isApproved) {

        dispatch({
          text: "Entries have been approved",
          alert: 'error',
          stayFor: 4000
        });
        router.replace( match.location.pathname);
        return
      } 
      
   // router.replace( match.location.pathname);
      setOpenConfirm(reqInfo)

    }).catch((e)=> {
      dispatch({
        text: e,
        alert: 'error',
        stayFor: 4000
      });
    }) 
  }, [token])

  const onConfirmApprove = async (req: any) => { 
   try {
       await  attemptApproval(req.id); 
      dispatch({
        text: "Entries approved successfully",
        alert: 'success',
        stayFor: 4000
      });
      setOpenConfirm(null)
      router.replace( match.location.pathname);
      setTimeout(()=> {
        location.reload()
     
      }, 500)
      setLimit(PAGE_SIZE);
   } catch (e) {
    dispatch({
      text: e,
      alert: 'error',
      stayFor: 4000
    });
   }
      
  }
  
  return (
    <div className={classes.root}>
      <PageTitle title="Supervisor Sign-off" subTitle="" sideText="" />
      <Spacer vertical spacing={3} />
      <div className={classes.requestsContainer}>
        {monthlySignoffRequests?.map(
          (monthlySignoffRequest: MonthlySignoffRequests, index: number) => (
            <div key = {index}>
              <p className={classes.monthText}>{monthlySignoffRequest.month}</p>
              <SignoffRequestRow>
                {monthlySignoffRequest.requests?.map((signoffRequest: any, index: number) => (
                  <SignoffRequestCard
                    key = {index}
                    firstName={signoffRequest?.candidate.firstName}
                    lastName={signoffRequest?.candidate.lastName}
                    placementName={signoffRequest?.placement?.name}
                    approval={signoffRequest?.approval}
                    entriesLength={
                      signoffRequest?.entries?.pageInfo.total || '0'
                    }
                    createdAt={
                      moment(signoffRequest?.createdAt).format('DD MMM YYYY') ||
                      ''
                    }
                    title={signoffRequest?.title}
                    PDFURL={signoffRequest?.PDFURL || ''}
                    fileUrl={signoffRequest?.entries?.edges.length >0 ? signoffRequest?.entries?.edges[0].fileUrl : ''}
                    supervisorSignOffPage={true}
                    signoffRequestID={signoffRequest?.id}
                    onApprovalSignoff = {
                      (signoffRequestId, isApproved) => {
                        setOpenConfirm(signoffRequest)
                      } 
                    }
                  />
                ))}
              </SignoffRequestRow>
            </div>
          )
        )}
      </div>
      <div>
        {(signoffRequests?.pageInfo?.total ?? 0) >
          (signoffRequests?.edges?.length ?? 0) && (
          <ShowMoreButtonContainer>
            <ShowMoreButton
              onClick={() => {
                setLimit(limit + PAGE_SIZE);
                router.push(`/app/signoffrequests?&limit=${limit + PAGE_SIZE}`);
              }}
            >
              Show More
            </ShowMoreButton>
          </ShowMoreButtonContainer>
        )}
      </div>
      {
        isOpenConfirm!= null && <GenericPopup
        open={isOpenConfirm != null}
        onClose={() => {
          setOpenConfirm(null)
          router.replace( match.location.pathname);
      
        }}
        title={'You are about to approve these entries'}
      >
        <ConfirmContent>
        { 
          isOpenConfirm.candidate ? (<p>Are you sure you want to approve entries named <b>{isOpenConfirm.title}</b> sent by { isOpenConfirm.candidate.firstName}?</p>) :
          (<p>Are you sure you want to approve entries named <b>{isOpenConfirm.title}</b>?</p>)
        }
       </ConfirmContent>
        <ButtonConfirmContainer>
          <Button archetype="grey" onClick={() =>  {
              setOpenConfirm(null)
              router.replace( match.location.pathname);
            }}>
              Cancel
          </Button>
          <Button archetype="submit" onClick={() => onConfirmApprove(isOpenConfirm)}>
              Approve
          </Button>
        </ButtonConfirmContainer>
      </GenericPopup>
      }
    </div>
  );
};

const SupervisorSignOffPageFrag = createFragmentContainer(
  SupervisorSignOffPage,
  {
    signoffRequests: graphql`
      fragment SupervisorSignOffPage_signoffRequests on SignoffRequestPage {
        edges {
          id
          createdAt
          title
          PDFURL
          approval
          candidate {
            firstName
            lastName
          }
          entries {
            edges {
              fileUrl
            }
            pageInfo {
              total
            }
          }
          placement {
            name
          }
        }
        pageInfo {
          total
          offset
          limit
          given
        }
      }
    `
  }
);

export default SupervisorSignOffPageFrag;
