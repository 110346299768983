import { graphql, fetchQuery } from 'react-relay';
import environment from 'api/environment';
import { helpersCourseQueryResponse } from './__generated__/helpersCourseQuery.graphql';
import { helpersSignoffPDFQuery } from './__generated__/helpersSignoffPDFQuery.graphql';

export async function getSignoffPDF(id: string) {
  const query = graphql`
    query helpersSignoffPDFQuery($id: ID!) {
      signoffPDF(id: $id)
    }
  `;
  const variables = { id };
  try {
    const response = await fetchQuery<helpersSignoffPDFQuery>(
      environment,
      query,
      variables,
    );
    return response.signoffPDF;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getCourse(id: number) {
  const query = graphql`
    query helpersCourseQuery($id: Int!) {
      course(id: $id) {
        ident: id
        name
        bannerImageURL
        introduction
        price
        specificTerms
      }
    }
  `;

  const variables = {
    id: id
  };

  try {
    const data = (await fetchQuery(
      environment,
      query,
      variables
    )) as helpersCourseQueryResponse;

    if (data.course) {
      return data.course;
    }
  } catch (err) {
    console.warn(err);
    return undefined;
  }

  return undefined;
}

// Stops the body from overflowing (usually when a slide in is open)
export function setBodyOverflow(allowOverflow: boolean) {
  var element = document.getElementById('_site_body');

  if (element && allowOverflow) {
    element.style.overflow = 'initial';
  }
  if (element && !allowOverflow) {
    element.style.overflow = 'hidden';
  }
}
