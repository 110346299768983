/* tslint:disable */
/* eslint-disable */
/* @relayHash eb635c0950adf86351885d470f1a886f */

import { ConcreteRequest } from "relay-runtime";
export type mutations_UpdateManagerMutationVariables = {
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    telephone?: string | null;
    password?: string | null;
};
export type mutations_UpdateManagerMutationResponse = {
    readonly updateManager: {
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly email: string;
        readonly telephone: string;
    } | null;
};
export type mutations_UpdateManagerMutation = {
    readonly response: mutations_UpdateManagerMutationResponse;
    readonly variables: mutations_UpdateManagerMutationVariables;
};



/*
mutation mutations_UpdateManagerMutation(
  $id: ID!
  $firstName: String
  $lastName: String
  $email: String
  $telephone: String
  $password: String
) {
  updateManager(input: {id: $id, firstName: $firstName, lastName: $lastName, email: $email, telephone: $telephone, password: $password}) {
    id
    firstName
    lastName
    email
    telephone
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "firstName",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "lastName",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "email",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "telephone",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "password",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "updateManager",
            "storageKey": null,
            "args": [
                {
                    "kind": "ObjectValue",
                    "name": "input",
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "email",
                            "variableName": "email"
                        },
                        {
                            "kind": "Variable",
                            "name": "firstName",
                            "variableName": "firstName"
                        },
                        {
                            "kind": "Variable",
                            "name": "id",
                            "variableName": "id"
                        },
                        {
                            "kind": "Variable",
                            "name": "lastName",
                            "variableName": "lastName"
                        },
                        {
                            "kind": "Variable",
                            "name": "password",
                            "variableName": "password"
                        },
                        {
                            "kind": "Variable",
                            "name": "telephone",
                            "variableName": "telephone"
                        }
                    ]
                }
            ],
            "concreteType": "Manager",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "firstName",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lastName",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "email",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "telephone",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "mutations_UpdateManagerMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "mutations_UpdateManagerMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "mutations_UpdateManagerMutation",
            "id": null,
            "text": "mutation mutations_UpdateManagerMutation(\n  $id: ID!\n  $firstName: String\n  $lastName: String\n  $email: String\n  $telephone: String\n  $password: String\n) {\n  updateManager(input: {id: $id, firstName: $firstName, lastName: $lastName, email: $email, telephone: $telephone, password: $password}) {\n    id\n    firstName\n    lastName\n    email\n    telephone\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'ec669e90e3e588ee21eb3dc0dc6b9f60';
export default node;
