import * as React from 'react';
//@ts-ignore
import { BrowserProtocol, queryMiddleware } from 'farce';
import {
  createFarceRouter,
  createRender,
  makeRouteConfig,
  Route,
  RouteRenderArgs
} from 'found';
//@ts-ignore
import { Resolver } from 'found-relay';
import environment from './api/environment';
import { graphql } from 'react-relay';
import LoginPage from 'views/Login';
import RecoverPassword from 'views/RecoverPassword';
import { ThemeProvider } from 'react-jss';
import theme from './helpers/theme';
import AppHolder from 'views/AppHolder';
import OrgOverview from 'views/OrgOverview';
import CoursesPage from 'views/CoursesPage';
import { SideModalProvider } from 'views/SideModalProvider';
import ResetPassword from 'views/ResetPassword';
import SignoffApproval from 'views/SignoffApproval';
import FinaliseAccount from 'views/FinaliseAccount';
import CandidatesPageFrag from 'views/CandidatesPage';
import CandidatePageFrag from 'views/CandidatePage';
import { ToastProvider } from 'components/Toast/ToastProvider';
import SupervisorSignOffPageFrag, {
  PAGE_SIZE
} from 'views/SignOff/SupervisorSignOffPage';
import {
  createMuiTheme,
  ThemeProvider as MaterialThemeProvider
} from '@material-ui/core/styles';

const protectedRenderer = (Comp: React.ReactNode) => (
  args: RouteRenderArgs
) => {
  // Sadly found-relay has no types...
  //@ts-ignore
  if (args?.error && args?.error.type == 'ErrUnauthorized') {
    args.match.router.push('/login');
    return;
  }
  if (!args.props) {
    return <div></div>;
  }
  //@ts-ignore
  return <Comp {...args.props} retry={args.retry} />;
};

const Router = createFarceRouter({
  historyProtocol: new BrowserProtocol(),
  historyMiddlewares: [queryMiddleware],
  routeConfig: makeRouteConfig(
    <Route>
      <Route
        path="/(login)?"
        Component={LoginPage}
        query={graphql`
          query App_Login_Query {
            manager {
              ...LoginPage_manager
            }
            user {
              ...LoginPage_user
            }
          }
        `}
        render={(args: any) => {
          if (!args.props) {
            return <LoginPage />;
          }
          return <LoginPage {...args.props} />;
        }}
      />
      <Route path="/(password)?" Component={RecoverPassword} />
      <Route path="/reset/:token" Component={ResetPassword} />
      <Route path="/approve/:token" Component={SignoffApproval} />
      <Route path="/finalise/:token" Component={FinaliseAccount} />
      <Route
        path="/app"
        Component={AppHolder}
        query={graphql`
          query App_Holder_Query {
            manager {
              ...AppHolder_manager
            }
          }
        `}
        render={protectedRenderer(AppHolder)}
      >
        <Route
          path="/"
          Component={CandidatesPageFrag}
          query={graphql`
            query App_CandidatesPage_Query(
              $offset: Int
              $limit: Int
              $orderBy: OrderBy
            ) {
              candidates(
                page: { offset: $offset, limit: $limit }
                orderBy: $orderBy
              ) {
                ...CandidatesPage_candidates
              }
              manager {
                ...CandidatesPage_manager
              }
            }
          `}
          prepareVariables={(params: any, { location }: any) => {
            const { offset, limit } = location.query;
            try {
              var page = {
                offset: parseInt(offset) || 0,
                limit: parseInt(limit) || 10
              };
              return page;
            } catch (err) {}
            return {
              offset: 0,
              limit: 10
            };
          }}
        />
        <Route
          path="/trainee/:id"
          Component={CandidatePageFrag}
          query={graphql`
            query App_CandidatePage_Query($uuid: UUID) {
              candidate(uuid: $uuid) {
                ...CandidatePage_candidate
              }
            }
          `}
          prepareVariables={(params: any) => {
            return { uuid: params.id };
          }}
        />
        <Route path="/courses" Component={CoursesPage} />
        <Route
          path="/signoffrequests"
          Component={SupervisorSignOffPageFrag}
          query={graphql`
            query App_SignoffRequestsPage_Query($limit: Int) {
              signoffRequests(page: { limit: $limit }) {
                ...SupervisorSignOffPage_signoffRequests
              }
            }
          `}
          prepareVariables={(params: any, { location }: any) => {
            const { limit } = location.query;

            try {
              var page = {
                limit: parseInt(limit) || PAGE_SIZE
              };
              return page;
            } catch (err) {}
            return {
              limit: PAGE_SIZE
            };
          }}
        />
      </Route>
    </Route>
  ),
  render: createRender({})
});

const materialTheme = createMuiTheme({
  typography: {
    fontFamily: "'Muli', sans-serif"
  },
  palette: {
    primary: {
      main: '#f37a21'
    }
  }
});

const App = () => (
  <MaterialThemeProvider theme={materialTheme}>
    <ThemeProvider theme={theme}>
      <ToastProvider>
        <SideModalProvider>
          <Router resolver={new Resolver(environment)} />
        </SideModalProvider>
      </ToastProvider>
    </ThemeProvider>
  </MaterialThemeProvider>
);

export default App;
