/* tslint:disable */
/* eslint-disable */
/* @relayHash 9121dbc6462c43a5bf23990548f04464 */

import { ConcreteRequest } from "relay-runtime";
export type SupervisorSignOffPageInfoMutationVariables = {
    token: string;
};
export type SupervisorSignOffPageInfoMutationResponse = {
    readonly signoffApprovalInfo: {
        readonly id: string;
        readonly title: string | null;
        readonly approval: boolean | null;
        readonly candidate: {
            readonly id: string;
            readonly firstName: string;
            readonly lastName: string;
            readonly email: string;
        } | null;
        readonly entries: {
            readonly edges: ReadonlyArray<{
                readonly id: string;
                readonly title: string;
            } | null> | null;
            readonly pageInfo: {
                readonly total: number;
                readonly offset: number;
                readonly limit: number;
            } | null;
        } | null;
        readonly placement: {
            readonly id: string;
            readonly name: string;
            readonly email: string | null;
        } | null;
    } | null;
};
export type SupervisorSignOffPageInfoMutation = {
    readonly response: SupervisorSignOffPageInfoMutationResponse;
    readonly variables: SupervisorSignOffPageInfoMutationVariables;
};



/*
mutation SupervisorSignOffPageInfoMutation(
  $token: String!
) {
  signoffApprovalInfo(input: {token: $token}) {
    id
    title
    approval
    candidate {
      id
      firstName
      lastName
      email
    }
    entries {
      edges {
        id
        title
      }
      pageInfo {
        total
        offset
        limit
      }
    }
    placement {
      id
      name
      email
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "token",
            "type": "String!",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "title",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
    } as any), v4 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "signoffApprovalInfo",
            "storageKey": null,
            "args": [
                {
                    "kind": "ObjectValue",
                    "name": "input",
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "token",
                            "variableName": "token"
                        }
                    ]
                }
            ],
            "concreteType": "SignoffRequest",
            "plural": false,
            "selections": [
                (v1 /*: any*/),
                (v2 /*: any*/),
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "approval",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "candidate",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Candidate",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "firstName",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "lastName",
                            "args": null,
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "entries",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "EntryPage",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "QWEEntry",
                            "plural": true,
                            "selections": [
                                (v1 /*: any*/),
                                (v2 /*: any*/)
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "pageInfo",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "total",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "offset",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "limit",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "placement",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Placement",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "name",
                            "args": null,
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "SupervisorSignOffPageInfoMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v4 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "SupervisorSignOffPageInfoMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v4 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "SupervisorSignOffPageInfoMutation",
            "id": null,
            "text": "mutation SupervisorSignOffPageInfoMutation(\n  $token: String!\n) {\n  signoffApprovalInfo(input: {token: $token}) {\n    id\n    title\n    approval\n    candidate {\n      id\n      firstName\n      lastName\n      email\n    }\n    entries {\n      edges {\n        id\n        title\n      }\n      pageInfo {\n        total\n        offset\n        limit\n      }\n    }\n    placement {\n      id\n      name\n      email\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '12af93f205bf9453135b475971717754';
export default node;
