import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import classNames from 'classnames';
import { Theme } from 'helpers/theme';
import Icon, { IconNames } from '../../../sharedComponents/core/Icon/Icon';
import AccutraineeLogo from 'assets/logo/accutrainee-logo.svg';

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    gridArea: 'side',
    background: 'white',
    display: 'flex',
    zIndex: 10,
    height: '100%',
    flexDirection: 'column',
    borderRight: '1px solid #EDEDED'
  },
  accutraineeLogo: {
    height: 49,
    marginTop: 36,
    marginBottom: 10
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: theme.colors.primaryWhite,
    marginTop: 50
  },
  tab: {
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    padding: '15px 0',
    opacity: 0.3,
    transition: 'background-color 0.3s linear, opacity 0.3s linear'
  },
  selected: {
    backgroundColor: theme.colors.hoverOrange,
    opacity: 1,
    transition: 'background-color 0.3s linear, opacity 0.3s linear'
  },
  fold: {
    height: '40px',
    width: '5px',
    borderRadius: '0 9px 9px 0',
    backgroundColor: theme.colors.secondaryOrange,
    opacity: 1,
    transition: 'opacity 1s linear'
  },
  noFold: {
    height: '40px',
    width: '5px',
    opacity: 0,
    transition: 'visibility 0s 1s, opacity 1s linear'
  },
  body: {
    padding: '30px 30px',
    backgroundColor: theme.colors.backgroundGrey,
    boxShadow: theme.shadows.body,
    flexGrow: 1
  },
  tabContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 39
  },
  iconHolder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 45
  }
}));

export interface Tab {
  id: number;
  title: string;
  icon?: IconNames;
  size?: number;
}

type Props = {
  tabs: Array<Tab>;
  selected: Tab;
  onClick: (tab: Tab) => void;
  className?: string;
};

function SideMenu({ tabs, selected, onClick, className }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classNames(classes.root, className)}>
      <img
        className={classes.accutraineeLogo}
        src={AccutraineeLogo}
        alt="Logo"
      />
      <div className={classNames(classes.menu)}>
        {tabs &&
          tabs.map((tab) => (
            <div
              key={tab.id}
              className={classNames(
                classes.tab,
                selected && selected.id === tab.id && classes.selected
              )}
              onClick={() => onClick(tab)}
            >
              <div
                className={classNames(
                  selected && selected.id === tab.id
                    ? classes.fold
                    : classes.noFold
                )}
              />
              <div className={classes.tabContent}>
                {tab.icon && (
                  <div className={classes.iconHolder}>
                    <Icon
                      name={tab.icon}
                      size={tab.size ?? 20}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                )}
                {tab.title}
              </div>
              <div />
            </div>
          ))}
      </div>
    </div>
  );
}

export default SideMenu;
