import * as React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import environment from 'api/environment';
import moment from 'moment';
import Dropdown from 'sharedComponents/core/Input/Dropdown';
import { ClassroomInstanceSelectorQueryResponse } from './__generated__/ClassroomInstanceSelectorQuery.graphql';
import { DropdownOption } from 'sharedComponents/core/Input/Dropdown/Dropdown';
import { select } from '@storybook/addon-knobs';

type Instance = {
  uuid: string;
  startDate: string;
  endDate: string;
};

type Props = {
  courseID: number;
  onSelected: (instance: Instance) => void;
};

function ClassroomInstanceSelector({ onSelected, courseID }: Props) {
  const [currentDate] = React.useState(new Date());
  const [selected, setSelected] = React.useState<DropdownOption>();
  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query ClassroomInstanceSelectorQuery(
          $courseID: Int!
          $afterDate: Time
        ) {
          course(id: $courseID) {
            ... on ClassroomCourse {
              instances(filter: { afterDate: $afterDate }) {
                edges {
                  uuid
                  startDate
                  endDate
                }
              }
            }
          }
        }
      `}
      variables={{
        courseID: courseID,
        afterDate: currentDate
      }}
      render={({ error, props }) => {
        if (props) {
          const { course } = props as ClassroomInstanceSelectorQueryResponse;
          if (course?.instances) {
            const dropdownOptions =
              course?.instances.edges
                ?.map((instance) => ({
                  title: `${moment(instance?.startDate).format(
                    'DD MMMM YYYY hh:mm A'
                  )} - ${moment(instance?.endDate).format(
                    'DD MMMM YYYY hh:mm A'
                  )}`,
                  id: instance?.uuid ?? '',
                  ...instance
                }))
                .reverse() ?? [];

            return (
              <Selector
                dropdownOptions={dropdownOptions}
                selected={selected}
                setSelected={(current: DropdownOption) => {
                  const selected = course.instances?.edges?.find(
                    (instance) => instance?.uuid == current.id
                  );

                  if (selected) onSelected(selected);
                  setSelected(current);
                }}
              />
            );
          }
        }
        return false;
      }}
    />
  );
}

type SelectorProps = {
  dropdownOptions: DropdownOption[];
  selected: any;
  setSelected: (current: DropdownOption) => void;
};
function Selector({ dropdownOptions, selected, setSelected }: SelectorProps) {
  React.useEffect(() => {
    if (selected) {
      setSelected(selected);
    } else if (dropdownOptions.length > 0) {
      setSelected(dropdownOptions[0]);
    }
  }, [selected, dropdownOptions]);
  return (
    <Dropdown
      placeholder="Course instance"
      selected={selected || dropdownOptions[0]}
      setSelected={setSelected}
      options={dropdownOptions}
    />
  );
}

export default React.memo(ClassroomInstanceSelector);
